import { useMemo } from 'react';
import { useLocalStorage } from 'hooks';
import { IInAppPurchase } from 'shared/types';
import usePageEnterExit from './useLeavePage';
import { useAppSelector } from './index';

export const useGetUserData = () => {
  const { get, set } = useLocalStorage();
  const { user, timeDiff, deleteFriendInfo, sellFriendInfo, userFriendFriends, userFriendSlots } = useAppSelector(
    (state) => state.user
  );

  // Проверка на наличие перепроданных слотов
  const hasResoldFriends = user?.slots?.some((_) => _.friend_owner_id !== user.user_id && _.friend_id !== 0);
  // Проверка полной энергии
  const isEnergyFull = user?.energy >= user?.max_energy;
  // Карточки магазина в локальном хранилище
  const inAppsLocal = get('in_apps', []);
  // Обновление локально Карточек магазина при загрузке страницы
  if (user?.in_apps) {
    set(
      'in_apps',
      user?.in_apps?.map((inApp: IInAppPurchase) => {
        const card = inAppsLocal.find((_) => _.id === inApp.id);
        return { id: inApp.id, viewed: card ? card.viewed : false };
      })
    );
  }
  // Проверка на наличие новых карточек
  const hasNewInApps = inAppsLocal?.some((_) => !_.viewed);
  // Убираем нотифы с магазина если выходим с этой страницы
  usePageEnterExit(
    '/shop',
    () => {},
    () => {
      set(
        'in_apps',
        inAppsLocal?.map((_) => ({ ..._, viewed: true }))
      );
    }
  );
  // Если буст полной энергии доступен
  const isFreeEnergyBoostActive = user?.free_energy_recovery_time < Date.now() / 1000;

  // const invitedFriends = useMemo(
  //   () => (user?.referral_ids ?? [])?.map((_) => user?.friends?.find((friend) => friend.id === _)),
  //   [user?.friends, user?.referral_ids]
  // );
  const invitedFriends = useMemo(
    () => (user?.friends || [])?.filter(({ referral_id }) => referral_id === user.user_id),
    [user?.friends]
  );
  return {
    user,
    timeDiff,
    deleteFriendInfo,
    sellFriendInfo,
    userFriendFriends,
    userFriendSlots,
    hasResoldFriends,
    isEnergyFull,
    hasNewInApps,
    isFreeEnergyBoostActive,
    invitedFriends,
    ...user,
  };
};
