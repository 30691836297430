import React, { FC, PointerEvent, ReactNode } from 'react';
import cn from 'classnames';
import s from './styles.module.scss';

interface ButtonProps {
  children: ReactNode;
  variant?: 'Green' | 'Blue' | 'Red' | 'White' | 'Disabled';
  onClick: (event?: PointerEvent<HTMLButtonElement>) => void;
  marker?: ReactNode;
  classNames?: string;
  isDisabled?: boolean;
}

export const Button: FC<ButtonProps> = ({ children, variant = 'Green', onClick, marker, classNames, isDisabled }) => {
  return (
    <button disabled={isDisabled} onClick={onClick} className={cn(s.commonButton, s[variant], classNames)}>
      {marker}
      {children}
    </button>
  );
};
