import React, { FC } from 'react';
import { Icon, UpdateCardsWrapper } from 'components';
import { abbreviateNumber } from 'shared/helpers/abbreviateNumber';
import { IInAppPurchase } from 'shared/types';
import s from './styles.module.scss';

interface MarketCardProps extends IInAppPurchase {
  marker?: boolean;
  onClick?: () => void;
}
export const ShopCard: FC<MarketCardProps> = ({ title, price, money, marker, onClick }) => {
  return (
    <UpdateCardsWrapper
      price={price}
      onClick={onClick}
      marker={
        marker && (
          <div className={s.marker}>
            <Icon iconName={'redMarker'} />
          </div>
        )
      }
      header={title}
      main={
        <div className={s.mainWrapper}>
          <div className={s.coinText}>+{abbreviateNumber(money)}</div>
          <div className={s.coinIcon}>
            <Icon iconName={'coin'} />
          </div>
        </div>
      }
      footer={
        <div className={s.price}>
          <Icon iconName={'starIcon'} size={[15, 14]} />
          {price}
        </div>
      }
      noEnoughLabel={false}
    />
  );
};
