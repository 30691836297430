import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ModalIdType } from 'components';
import { Friend, NewFriendInfo, SlotType } from 'shared/types';
import { HelpsType } from 'src/feature';

interface InitialState {
  modalId: ModalIdType | null;
  hireEmployeeModalData: Friend | null;
  hireEmployeeOfficeData: Friend | null;
  slotData: SlotType & { slot_id: number };
  addStaffData: { slot_id: number };
  newFriendInfo: NewFriendInfo | null;
  helpId: HelpsType | null;
}

const initialState: InitialState = {
  modalId: null,
  hireEmployeeModalData: null,
  hireEmployeeOfficeData: null,
  slotData: null,
  addStaffData: null,
  newFriendInfo: null,
  helpId: null,
};

const modalSlice = createSlice({
  name: 'modalSlice',
  initialState,
  reducers: {
    modalIsOpen(state, action) {
      state.modalId = action.payload;
    },
    modalIsClose(state) {
      state.modalId = null;
    },
    helpIsOpen(state, action: PayloadAction<HelpsType>) {
      state.helpId = action.payload;
    },
    helpIsClose(state) {
      state.helpId = null;
    },
    setHireEmployeeModalData(state, action) {
      state.hireEmployeeModalData = action.payload;
    },
    setHireEmployeeOfficeData(state, action) {
      state.hireEmployeeOfficeData = action.payload;
    },
    setSlotData(state, action) {
      state.slotData = action.payload;
    },
    setAddStaffData(state, action) {
      state.addStaffData = action.payload;
    },
    setNewFriendInfo: (state, action) => {
      state.newFriendInfo = action.payload;
    },
    clearStaffOfficeOwner: (state) => {
      state.hireEmployeeOfficeData = null;
      state.hireEmployeeModalData = null;
    },
  },
});

export const {
  modalIsOpen,
  modalIsClose,
  setHireEmployeeModalData,
  setHireEmployeeOfficeData,
  setSlotData,
  setAddStaffData,
  setNewFriendInfo,
  helpIsClose,
  helpIsOpen,
  clearStaffOfficeOwner,
} = modalSlice.actions;

export default modalSlice.reducer;
