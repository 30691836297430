import { useGetUserData } from './useGetUserData';

export const useGetOwner = (owner_id: number) => {
  const { friends, missed_owners, friends_friends } = useGetUserData();

  // Владелец из списка друзей
  const ownerFromFriends = friends?.find((friend) => friend.id === owner_id);
  // Владелец из списка Друзей друзей
  const ownerFromFriendsFriends = friends_friends?.find((friend) => friend.id === owner_id);
  // Владелец из списка Людей которых нет в списках друзей
  const missedOwner = missed_owners?.find((owner) => owner.id === owner_id);

  const owner = ownerFromFriends
    ? ownerFromFriends
    : ownerFromFriendsFriends
      ? ownerFromFriendsFriends
      : missedOwner
        ? missedOwner
        : null;

  return owner;
};
