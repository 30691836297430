import { SkillEnum } from 'shared/types';
import { gameSocket } from '.';

// Функция для отправки сообщения через WebSocket
function sendMessage(request: object) {
  if (gameSocket && gameSocket.readyState === WebSocket.OPEN) {
    try {
      gameSocket.send(JSON.stringify(request));
    } catch (error) {
      console.log(error);
    }
  }
}

// Отправка команды "click" на сервер
export function sendClick(count?: number) {
  sendMessage({ cmd: 'click', count });
}

// Покупка умения
export function buySkill(skill: SkillEnum) {
  sendMessage({ cmd: 'buy_skill', skill });
}

// Запрос списка друзей с возможным смещением (offset)
export function sendGetFriends(offset: number = 0) {
  sendMessage({ cmd: 'get_friends', offset });
}

// Покупка друга по его ID и цене
export function buyFriend(friendID: number, price: number) {
  sendMessage({ cmd: 'buy_friend', friend_id: friendID, price });
}

// Получение информации о друге по его ID
export function getFriendInfo(friendID: number) {
  sendMessage({ cmd: 'get_friend_info', friend_id: friendID });
}

// Управление слотом: добавление или удаление друга в слот
export function sendManageSlot(slotID: number, friendID: number) {
  sendMessage({ cmd: 'manage_slot', slot_id: slotID, friend_id: friendID });
}

// Запуск работы
export function sendStartWork(slot_num: number) {
  sendMessage({ cmd: 'start_work', slot_num });
}

// Получение дохода от работы
export function sendGetWorkRevenue(slot_num: number) {
  sendMessage({ cmd: 'get_work_revenue', slot_num });
}

// Отправка команды покупки внутриигрового товара
export function sendPurchase(inappID: string | number) {
  sendMessage({ cmd: 'purchase', inapp_id: inappID });
}

// Буст до полной энергии
export function sendFreeEnergy() {
  sendMessage({ cmd: 'free_energy_recovery' });
}
export function sendWipe() {
  sendMessage({ cmd: 'wipe' });
}
