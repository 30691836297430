import { FC, useState, useEffect, MouseEvent, CSSProperties } from 'react';
import cn from 'classnames';

// Используем require.context для получения всех файлов в папке icons
const iconsContext = require.context('assets/icons', false, /\.(webp|png|svg)$/);

// Извлекаем имена файлов без расширений
const iconNames = iconsContext.keys()?.map((key) => key.replace('./', '').replace(/\.(webp|png|svg)$/, '')) as string[];

// Создаем тип на основе имен файлов
type IconName = (typeof iconNames)[number];

export interface IconProps {
  classNames?: string;
  iconName: IconName;
  size?: [number, number];
  onClick?: (e: MouseEvent<HTMLImageElement>) => void;
}

export const Icon: FC<IconProps> = ({ classNames, iconName, size, onClick }) => {
  const [src, setSrc] = useState<string | null>(null);

  useEffect(() => {
    const loadImage = async () => {
      const formats: Array<'webp' | 'png' | 'svg'> = ['webp', 'png', 'svg']; // Доступные форматы
      for (const format of formats) {
        try {
          const image = await import(`assets/icons/${iconName}.${format}`);
          setSrc(image.default);
          return; // Остановить попытки после успешной загрузки
        } catch (error) {
          // Продолжаем, если не удалось загрузить изображение с данным форматом
        }
      }
      setSrc(null); // Если ни один формат не подошел
    };

    loadImage();
  }, [iconName]);

  const styles: CSSProperties = { width: size?.[0] || '', height: size?.[1] || '', pointerEvents: 'all' };

  return src ? (
    <img
      onClick={onClick}
      src={src + '?reset=true '}
      style={styles}
      className={cn(classNames)}
      alt={`${iconName} icon`}
    />
  ) : null;
};
