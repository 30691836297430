import { RefObject } from 'react';
import coin from '../assets/icons/coin.webp';

interface UseCoinsDropProps {
  ref: RefObject<any>;
}

export const useCoinsDrop = ({ ref }: UseCoinsDropProps) => {
  const generate = (coins: number) => {
    const container = ref.current;
    const divs = [];

    if (container) {
      for (let i = 0; i < coins && i < 50; i++) {
        const newDiv = document.createElement('div');
        newDiv.style.background = `url(${coin})`;
        newDiv.style.backgroundSize = '100%';
        newDiv.style.width = '20px';
        newDiv.style.height = '20px';
        newDiv.style.position = 'absolute';
        newDiv.style.zIndex = '20';
        newDiv.style.left = `${Math.random() * 100}%`;

        container.appendChild(newDiv);
        divs.push(newDiv);
      }

      divs.forEach((div) => {
        const rect = div.getBoundingClientRect();
        const centerX = window.innerWidth / 2;
        const dur = (Math.random() % 10) + 1;

        const isLeftOfCenter = rect.left < centerX;

        setTimeout(() => {
          div.style.transition = `transform ${dur}s ease-in-out`;
          const translateX = isLeftOfCenter ? centerX - rect.left : -(rect.left - centerX);
          div.style.transform = `translate(${translateX}px, ${-rect.top + 30}px)`;

          div.addEventListener('transitionend', () => {
            div.remove(); // Удаляем элемент из DOM
          });
        });
      });
    }
  };
  return { generate };
};
