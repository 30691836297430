import { IUpdateCard, LanguagesEnum } from 'shared/types';

// Флаг запуска дев мода
export const isProd = false;

// Инициализация телеграмм веб апп
export const TG_WEB_APP = (window as any).Telegram?.WebApp;

// Инит дата для авторизации или девмода
export const initData = process.env.NODE_ENV === 'production' ? TG_WEB_APP.initData : process.env.REACT_APP_INIT_DATA;

export const inviteLink = (user_id: number) => {
  const link = !isProd
    ? `https://t.me/share/url?url=https://t.me/gamegears_test_bot/verstka?startapp=r_${user_id}&text=%0DHelp%20me%20earn%20and%20become%20an%20office%20director%20yourself!%20💼%0A💰%20Get%20%2B1k%20money%20as%20your%20first%20gift%0A🚀%20Invite%20your%20friends%20and%20boost%20our%20total%20income!`
    : `https://t.me/share/url?url=https://t.me/OfficeEmpireBot/game?startapp=r_${user_id}&text=%0DHelp%20me%20earn%20and%20become%20an%20office%20director%20yourself!%20💼%0A%09💰%20Get%20%2B1k%20money%20as%20your%20first%20gift%0A%09🚀%20Invite%20your%20friends%20and%20boost%20our%20total%20income!`;
  const copyLink = !isProd
    ? `https://t.me/gamegears_test_bot/verstka?startapp=r_${user_id}`
    : `https://t.me/OfficeEmpireBot/game?startapp=r_${user_id}`;

  return { link, copyLink };
};

export const marketTabs = [
  { id: 1, text: 'friendsMarket.myFriends' },
  {
    id: 2,
    text: 'friendsMarket.friendsFriends',
  },
  {
    id: 3,
    text: 'friendsMarket.newPlayers',
  },
  {
    id: 4,
    text: 'friendsMarket.topPlayers',
  },
];

// Список определенного порядка апдейтов
export const upgradeCards: IUpdateCard[] = [
  {
    id: 2,
    title: 'HR Guru',
    description: 'updateCards.hrGuru',
    type: 'hr_guru',
  },
  {
    id: 6,
    title: 'Hardy',
    description: 'updateCards.hardy',
    type: 'hardy',
  },

  {
    id: 5,
    title: 'Clicker Man',
    description: 'updateCards.clickerMan',
    type: 'clickerman',
  },
  {
    id: 7,
    title: 'Freshman',
    description: 'updateCards.freshman',
    type: 'freshman',
  },
  {
    id: 1,
    title: 'Big Boss',
    description: 'updateCards.bigBoss',
    type: 'big_boss',
  },
  {
    id: 3,
    title: 'Motivator',
    description: 'updateCards.motivator',
    type: 'motivator',
  },
  {
    id: 4,
    title: 'Team Leader',
    description: 'updateCards.teamLeader',
    type: 'team_leader',
  },
  {
    id: 5,
    title: 'Trader',
    description: 'updateCards.trader',
    type: 'trader',
  },
];

// Список локалей
export const languages = [
  { id: 1, label: 'English EN', value: LanguagesEnum.EN },
  { id: 2, label: 'Русский RU', value: LanguagesEnum.RU },
  { id: 3, label: 'Français FR', value: LanguagesEnum.FR },
  { id: 4, label: 'Deutsch DE', value: LanguagesEnum.DE },
  { id: 5, label: 'Español ES', value: LanguagesEnum.ES },
  { id: 6, label: 'ไทย Thai TH', value: LanguagesEnum.TH },
  { id: 7, label: "O'zbekcha UZ", value: LanguagesEnum.UZ },
  { id: 8, label: 'Қазақша KZ', value: LanguagesEnum.KZ },
];

// Расположение столов на странице офиса и офиса друга
export const placeOrder = {
  1: [[0, 1]],
  2: [[0, 2]],
  3: [
    [0, 1],
    [1, 3],
  ],
  4: [
    [2, 4],
    [0, 2],
  ],
  5: [
    [0, 1],
    [1, 4],
    [4, 5],
  ],
  6: [
    [0, 3],
    [3, 6],
  ],
  7: [
    [0, 2],
    [2, 5],
    [5, 7],
  ],
  8: [
    [0, 2],
    [2, 5],
    [5, 8],
  ],
  9: [
    [0, 3],
    [3, 6],
    [6, 9],
  ],
  10: [
    [0, 3],
    [3, 7],
    [7, 10],
  ],
};
