import { FC, MouseEvent, PointerEvent } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Avatar, Icon, ModalIdType, OwnerLine, UserCardsWrapper } from 'components';
import { abbreviateNumber } from 'shared/helpers/abbreviateNumber';
import { Friend, INotificationType } from 'shared/types';
import { useAppDispatch, useGetOwner, useGetUserData, useNoEnoughMoney } from 'src/hooks';
import { modalIsOpen, setHireEmployeeModalData } from 'store/slices/modalSlice';
import { calculateIncomeFormula, calculateResellFormula } from 'shared/helpers/calculateIncomeFormula';
import { truncateText } from 'shared/helpers/truncate';
import { buyFriend } from 'store/service/methods';
import { openHelpModal } from '../Modals/HelpModal/HelpModal';
import s from './styles.module.scss';

interface EmployeesCardProps extends Friend {
  marker?: boolean;
  onClick?: (event: PointerEvent<HTMLButtonElement>) => void;
  friend?: Friend;
  rating?: number;
}

export const FriendCard: FC<EmployeesCardProps> = ({ onClick, friend, rating }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { handleClick } = useNoEnoughMoney();
  const { user_id, money } = useGetUserData();
  const { first_name, last_name, photo_url, power, price, username, owner_id } = friend || {};

  // Достаточно денег для покупки
  const isMoneyEnough = money >= price;
  // Процент перепродажи
  const resell = calculateResellFormula(price);
  // Сотрудник нанят
  const isHire = owner_id === user_id;
  const owner = useGetOwner(friend?.owner_id);

  // Обработчик клика на карточку друга для открытия подробной инфы
  const hireEmployee = () => {
    dispatch(setHireEmployeeModalData(friend));
    dispatch(modalIsOpen(ModalIdType.HIRE_STAFF));
  };

  // Обработчик покупки друга
  const hireFriend = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    if (isMoneyEnough) {
      buyFriend(friend?.id, price);
      return;
    }
    handleClick({ event: e, textType: INotificationType.NO_ENOUGH_MONEY });
  };

  // Кнопка покупки друга
  const hireButton = () => {
    return (
      <div className={cn(s.button)} onClick={hireFriend}>
        <div className={s.hireButtonContent}>
          <div>{t('common.hire')}</div>

          <div className={s.priceWrapper}>
            <div className={s.coinIcon}>
              <Icon iconName={'coin'} size={[14, 14]} />
            </div>

            <div className={cn(s.text, isMoneyEnough ? s.textGold : s.textRed)}>{abbreviateNumber(price)}</div>
          </div>
        </div>
      </div>
    );
  };

  // Инфа стоимости перепродажи друга
  const sellInfo = () => {
    return (
      <div className={s.sellInfo}>
        <div className={s.sellTitle}>{t('common.sellPrice')}</div>

        <div className={s.sellCounter}>
          <Icon iconName={'coin'} size={[14, 14]} />
          <div>{abbreviateNumber(Number(price) + Number(resell))}</div>
        </div>
      </div>
    );
  };

  // Открыть подсказку
  const handleOpenInfo = (e: MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    openHelpModal('resell');
  };

  return (
    <UserCardsWrapper
      rating={rating}
      onClick={(e) => {
        hireEmployee();
        onClick?.(e);
      }}
    >
      <div className={s.cardWrapper}>
        {isHire && <div className={s.hired}>{t('common.hired')}</div>}
        {!isHire && <OwnerLine owner={owner} />}

        <div className={s.cardInfoWrapper}>
          <div className={s.userNameWrapper}>
            <div>
              <Avatar variant={'Employees'} image={photo_url} />
            </div>

            <div className={s.userName}>
              <div>{truncateText(`${first_name} ${last_name}`, 25)}</div>

              {username && <div className={s.secondLine}>({truncateText(username, 13)})</div>}
            </div>
          </div>

          <div className={s.userSkills}>
            <div className={s.skill}>
              <span>{t('common.power')}</span>
              <Icon iconName={'power'} size={[13, 13]} />
              <div className={s.powerCounter}>{abbreviateNumber(power)}</div>
            </div>

            <div className={s.skill}>
              <span>{t('common.income')}</span>
              <Icon iconName={'coin'} size={[13, 13]} />
              <div className={s.info}>
                {abbreviateNumber(calculateIncomeFormula(friend?.power))}
                <span>{t('common.perH')}</span>
              </div>
            </div>

            <div className={s.skill}>
              <span>{t('common.resell')}</span>
              <Icon iconName={'coin'} size={[13, 13]} />
              <div className={s.info}>{abbreviateNumber(resell)}</div>
              <Icon iconName={'info'} size={[12, 12]} onClick={handleOpenInfo} />
            </div>
          </div>
          {user_id != friend.id && <div className={s.buttons}>{!isHire ? hireButton() : sellInfo()}</div>}
        </div>
      </div>
    </UserCardsWrapper>
  );
};
