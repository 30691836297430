import { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon, Marker } from 'components';
import { useGetUserData, useSettings, useTimeLeft } from 'hooks';
import { abbreviateNumber } from 'shared/helpers/abbreviateNumber';
import { Friend } from 'shared/types';
import s from './styles.module.scss';

export interface StaffInfoProps {
  isStaffWork?: boolean;
  friend_id?: number;
  isFriendsTables: boolean;
  friendsFriend: Friend;
  start_time?: number;
  end_time?: number;
  isSold?: boolean;
  classNames?: string;
}

export const StaffInfo: FC<StaffInfoProps> = ({
  isStaffWork,
  friend_id,
  isFriendsTables,
  friendsFriend,
  end_time,
  isSold,
  classNames,
}) => {
  const { t } = useTranslation();
  // Получение данных о пользователе и друзьях
  const { friends, skill_values, revenue_formula_n, revenue_formula_m } = useGetUserData();
  const { viewStaffInfo } = useSettings();
  // Локальное состояние для отображения оставшегося времени работы сотрудника
  const timeLeft = useTimeLeft(end_time, t('common.done'));

  // То что будет отображаться в плашке слота если это оффис друга
  const friendsFriendUsername = friendsFriend?.username ? friendsFriend?.username : friendsFriend?.first_name;
  // То что будет отображаться в плашке слота если это мой оффис
  const friend = friends?.find((friend) => friend.id === friend_id);
  const friendUsername = friend?.username ? friend?.username : friend?.first_name;

  return !isSold ? (
    <div className={cn(s.staffInfo, classNames)}>
      {/* Отображение таймера, если сотрудник работает */}
      {isStaffWork && !isFriendsTables && <div className={s.clock}>{timeLeft}</div>}

      <h6>
        {isFriendsTables ? friendsFriendUsername : friendUsername}
        <br />
      </h6>

      {/* Дополнительная информация о сотруднике, если включено отображение */}
      <div className={viewStaffInfo ? s.visible : s.hidden}>
        <div className={s.staffInfoWrapper}>
          <div className={s.icon}>
            <Icon iconName={'coin'} />
          </div>
          <div className={s.profit}>
            {abbreviateNumber(
              Number(
                (
                  (revenue_formula_n + friends?.find((friend) => friend.id === friend_id)?.power / revenue_formula_m) *
                  (1 + skill_values?.['team_leader'] / 100)
                ).toFixed(2)
              )
            )}
            /h
          </div>
        </div>

        <div className={s.staffInfoWrapper}>
          <div className={s.icon}>
            <Icon iconName={'power'} />
          </div>
          <div className={s.power}>{abbreviateNumber(friends?.find((friend) => friend.id === friend_id)?.power)}</div>
        </div>
      </div>
    </div>
  ) : (
    <div className={cn(s.staffInfo, s.disabled)}>
      {/* Маркер нотиф */}
      {isSold && <Marker classNames={s.marker} />}

      <h6>
        {isFriendsTables
          ? friendsFriend?.username
            ? friendsFriend?.username
            : friendsFriend?.first_name
          : friends?.find((friend) => friend.id === friend_id)?.username
            ? friends?.find((friend) => friend.id === friend_id)?.username
            : friends?.find((friend) => friend.id === friend_id)?.first_name}
        <br />
      </h6>
    </div>
  );
};
