import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const usePageEnterExit = (pagePath: string, onEnter: () => void, onExit: () => void) => {
  const location = useLocation();
  const [wasOnPage, setWasOnPage] = useState(false);

  useEffect(() => {
    const isOnPage = location.pathname === pagePath;

    if (isOnPage && !wasOnPage) {
      onEnter();
      setWasOnPage(true);
    }

    if (!isOnPage && wasOnPage) {
      onExit();
      setWasOnPage(false);
    }
  }, [location, pagePath, onEnter, onExit, wasOnPage]);
};

export default usePageEnterExit;
