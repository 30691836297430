import { useState } from 'react';
import { SettingTabs } from 'shared/types';
import { ChangeLanguage } from './ChangeLanguage/ChangeLanguage';
import { SettingsMain } from './SettingsMain/SettingsMain';
import s from './styles.module.scss';

const SettingsPage = () => {
  const [changeTab, setChangeTab] = useState(SettingTabs.SETTINGS_MAIN);

  const tabs = {
    main: <SettingsMain onChange={(tab) => setChangeTab(tab)} />,
    language: <ChangeLanguage onChange={() => setChangeTab(SettingTabs.SETTINGS_MAIN)} />,
  };

  return <div className={s.wrapper}>{tabs[changeTab]}</div>;
};

export default SettingsPage;
