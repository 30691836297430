import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { CountScore, FriendCardShort } from 'feature';
import { useAppDispatch, useGetOwner, useGetUserData } from 'hooks';
import { modalIsClose } from 'store/slices/modalSlice';
import { clearWelcomeBackInfo } from 'store/slices/userSlice';
import s from './styles.module.scss';

export const WelcomeBackModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { offline_revenue, sell_friend_info, owner_id, friends, self_sell_profit } = useGetUserData();
  const owner = useGetOwner(owner_id);

  const handleCloseModal = () => {
    dispatch(clearWelcomeBackInfo());
    dispatch(modalIsClose());
  };

  return (
    <div className={s.welcomeBackModalContent}>
      <h2 className={s.title}>{t('welcomeBack.title')}</h2>

      <div className={s.notificationWrapper}>
        {Boolean(offline_revenue) && (
          <>
            <h5 className={s.subTitle}>{t('welcomeBack.income')}</h5>
            <CountScore count={offline_revenue} />
          </>
        )}

        <div className={s.notificationContent}>
          {Boolean(self_sell_profit) && (
            <>
              <h5 className={s.subTitle}>{t('welcomeBack.newOwner')}</h5>
              <FriendCardShort variant="resell" data={owner} />
            </>
          )}

          {sell_friend_info && (
            <>
              <h5 className={s.subTitle}>{t('welcomeBack.lostStaff')}</h5>

              <div className={s.employeesNotification}>
                {sell_friend_info?.map((info) => {
                  if (!info.friend_id) return;
                  const friend = friends?.find((_) => _.id === info?.friend_id);
                  return <FriendCardShort sellData={info} data={friend} variant="resell" key={info.friend_id} />;
                })}
              </div>
            </>
          )}
        </div>
      </div>

      <Button onClick={handleCloseModal}>{t('common.takeIncome')}</Button>
    </div>
  );
};
