import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { useAppDispatch } from 'hooks';
import { SlotType } from 'shared/types';
import { sendManageSlot } from 'store/service/methods';
import { modalIsClose } from 'store/slices/modalSlice';
import s from './styles.module.scss';

export const ConfirmModal = ({ data }: { data: SlotType & { slot_id: number } }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(modalIsClose());
  };

  const handleYes = () => {
    if (data?.friend_id) {
      sendManageSlot(data?.slot_id, 0);
    }
  };

  return (
    <div className={s.wrapper}>
      <h2>{t('common.areYouSure')}</h2>

      <div className={s.buttonWrapper}>
        <Button onClick={handleClose} variant={'Red'}>
          {t('common.no')}
        </Button>

        <Button onClick={handleYes}>{t('common.yes')}</Button>
      </div>
    </div>
  );
};
