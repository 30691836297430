import { FC } from 'react';
import cn from 'classnames';
import { Marker } from 'components';
import { useAppDispatch, useGetUserData } from 'hooks';
import { modalIsOpen } from 'store/slices/modalSlice';
import refreshEnergyImage from 'assets/images/energy_asset.webp';
import { ModalIdType } from '../ModalWrapper/types';
import s from './styles.module.scss';

export interface RefreshEnergyProps {
  classNames?: string;
}

export const RefreshEnergy: FC<RefreshEnergyProps> = ({ classNames }) => {
  const dispatch = useAppDispatch();
  const { isFreeEnergyBoostActive } = useGetUserData();

  const handleClickRefreshEnergy = () => {
    dispatch(modalIsOpen(ModalIdType.REFRESH_ENERGY));
  };

  return (
    <div className={cn(s.refreshEnergyImage, classNames)} onClick={handleClickRefreshEnergy}>
      {isFreeEnergyBoostActive && <Marker classNames={s.marker} />}
      <img src={refreshEnergyImage} alt="refresh energy image" />
    </div>
  );
};
