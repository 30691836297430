import React, { FC } from 'react';
import { Icon } from 'components';
import s from './styles.module.scss';

interface EnergyLimitProps {
  energy: number;
  energyLimit: number;
}

export const EnergyLimit: FC<EnergyLimitProps> = ({ energy, energyLimit }) => {
  return (
    <div className={s.energyLimit}>
      <div className={s.iconWrapper}>
        <Icon iconName={'lightning'} />
      </div>
      <p>{energy >= 0 ? Number(Math.floor(energy)).toFixed(0) : 0}</p>/
      <p>{energyLimit ? Number(energyLimit).toFixed(0) : 0}</p>
    </div>
  );
};
