import React, { FC } from 'react';
import cn from 'classnames';
import { Icon } from 'components';
import outOfSpot from 'assets/images/officeBg/chair.webp';
import s from './styles.module.scss';

export interface EmptySlotProps {
  classNames?: string;
  withButton?: boolean;
}

export const EmptySlot: FC<EmptySlotProps> = ({ classNames, withButton = true }) => {
  return (
    <div className={cn(classNames)}>
      {withButton && (
        <button className={s.addSlotButton}>
          <Icon iconName={'plus'} />
        </button>
      )}

      {/* Изображение неработающего сотрудника */}
      <img src={outOfSpot} alt="no working employees" />
    </div>
  );
};
