import { FC } from 'react';
import cn from 'classnames';
import defaultImage from 'assets/icons/avatar.webp';
import defaultSquareImage from 'assets/icons/avatar_square.webp';
import s from './styles.module.scss';

interface AvatarProps {
  image?: string;
  variant?: 'User' | 'Employees' | 'Owner' | 'Office' | 'Square';
  noActive?: boolean;
  className?: string;
}

export const Avatar: FC<AvatarProps> = ({ image, variant = 'User', noActive, className }) => {
  const avatarImage = image || (variant === 'Square' ? defaultSquareImage : defaultImage);

  return (
    <div className={cn(s.avatar, s[variant], className)}>
      <div
        className={cn(s.userAvatar, { [s.noActive]: noActive })}
        style={{ backgroundImage: `url("${avatarImage}")` }}
      />
    </div>
  );
};
