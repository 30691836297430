import { BossCard, UpdateCard } from 'feature';
import { useGetUserData } from 'hooks';
import { upgradeCards } from 'consts';
import s from './bossPage.module.scss';

const BossPage = () => {
  const { user } = useGetUserData();

  return (
    <div className={s.wrapper}>
      {user && <BossCard {...user} />}

      <div className={s.cardWrapper}>
        {upgradeCards?.map((card) => (
          <UpdateCard
            key={card.type}
            {...card}
            skillLevel={user?.skill_levels[card.type]}
            upgradeCost={user?.upgrade_costs[card.type]}
            valueAdd={user?.skill_values_add[card.type]}
            skillValue={user?.skill_values[card.type]}
          />
        ))}
      </div>
    </div>
  );
};
export default BossPage;
