import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { TG_WEB_APP } from 'consts';
import enTranslation from './locales/en.json';
import ruTranslation from './locales/ru.json';
import frTranslation from './locales/fr.json';
import deTranslation from './locales/de.json';
import esTranslation from './locales/es.json';
import thTranslation from './locales/th.json';
import uzTranslation from './locales/uz.json';
import kzTranslation from './locales/kz.json';

i18n
  .use(initReactI18next) // Интеграция i18next с React
  .init({
    fallbackLng: localStorage.getItem('language') || TG_WEB_APP?.initDataUnsafe?.user?.language_code || 'en', // Язык по умолчанию, если перевод для текущего языка недоступен
    debug: true, // Включаем debug-режим для отслеживания процесса инициализации
    interpolation: {
      escapeValue: false, // Отключаем экранирование строк, т.к. React сам экранирует их
    },
    resources: {
      en: {
        translation: enTranslation, // Загружаем переводы для английского
      },
      ru: {
        translation: ruTranslation, // Загружаем переводы для русского
      },
      fr: {
        translation: frTranslation, // Загружаем переводы для русского
      },
      de: {
        translation: deTranslation, // Загружаем переводы для русского
      },
      es: {
        translation: esTranslation, // Загружаем переводы для русского
      },
      th: {
        translation: thTranslation, // Загружаем переводы для русского
      },
      uz: {
        translation: uzTranslation, // Загружаем переводы для русского
      },
      kz: {
        translation: kzTranslation, // Загружаем переводы для русского
      },
    },
  });

declare module 'react-i18next' {
  interface CustomTypeOptions {
    defaultNS: 'translation';
    resources: {
      translation: typeof enTranslation; // Автоматическое определение типов из файла перевода
    };
  }
}

export default i18n;
