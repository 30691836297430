import { useGetUserData } from 'src/hooks';

export const calculateIncomeFormula = (power: number) => {
  const { revenue_formula_m, revenue_formula_n, skill_values } = useGetUserData();
  return (revenue_formula_n + power / revenue_formula_m) * (1 + skill_values?.['team_leader'] / 100);
};

export const calculateResellFormula = (price: number) => {
  const { skill_values, price_modifier } = useGetUserData();
  return Math.ceil(price * price_modifier * (skill_values?.['trader'] / 100));
};
