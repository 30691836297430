import { FC, ReactNode } from 'react';
import cn from 'classnames';
import s from './styles.module.scss';

interface WrapperProps {
  children: ReactNode;
  classNames?: string;
}
export const TitleWrapper: FC<WrapperProps> = ({ children, classNames }) => {
  return <div className={cn(s.countContainer, classNames)}>{children}</div>;
};
