import { useEffect } from 'react';
import throttle from 'lodash/throttle';
import { useDispatch } from 'react-redux';
import { Buttons } from 'shared/types';
import { changeButtonDisabled, changeButtonState } from 'store/slices/officeSlice';
import { useAppSelector, useGetUserData } from '.';

export const useUpdateOffice = () => {
  const dispatch = useDispatch();
  const { user_id, slots, friends, timeDiff } = useGetUserData();
  const buttonState = useAppSelector((state) => state.office.buttonState);

  const updateStartWorkButton = () => {
    if (!slots) {
      if (buttonState !== Buttons.WORKING) {
        dispatch(changeButtonState(Buttons.WORKING));
      }
      return;
    }

    const now = Math.floor(Date.now() / 1000);
    let working_done = false;
    let still_working = false;
    let allResoldOrEmpty = true;
    let hasFriendsInSlots = false;
    let hasIdleFriends = false;
    let hasResoldFriendsWithRevenue = false;

    for (const slot of slots) {
      const friend = friends?.find((f) => f.id === slot.friend_id);
      if (friend) {
        if (slot.revenue > 0) {
          if (slot.end_time + timeDiff <= now) {
            if (friend?.owner_id === user_id) {
              working_done = true;
              allResoldOrEmpty = false;
            } else {
              hasResoldFriendsWithRevenue = true;
            }
          } else if (slot.end_time + timeDiff > now && friend?.owner_id === user_id) {
            still_working = true;
            allResoldOrEmpty = false;
          }
        } else if (friend?.owner_id === user_id) {
          hasIdleFriends = true;
          allResoldOrEmpty = false;
        }

        if (friend?.owner_id === user_id) {
          hasFriendsInSlots = true;
        }
      }

      if (hasResoldFriendsWithRevenue || working_done) break;
    }

    let newButtonState = null;
    if (hasResoldFriendsWithRevenue) {
      newButtonState = Buttons.CLEM;
    } else if (working_done) {
      newButtonState = Buttons.CLEM;
    } else if (still_working && !hasIdleFriends) {
      newButtonState = Buttons.WORKING;
    } else if (allResoldOrEmpty) {
      newButtonState = Buttons.WORKING;
    } else {
      dispatch(changeButtonDisabled(!hasFriendsInSlots));
      newButtonState = Buttons.START;
    }

    if (buttonState !== newButtonState) {
      dispatch(changeButtonState(newButtonState));
    }
  };

  useEffect(() => {
    const updateButtonWithThrottle = throttle(updateStartWorkButton, 1000);
    updateButtonWithThrottle();
    const interval = setInterval(updateButtonWithThrottle, 1000);

    return () => {
      clearInterval(interval);
      updateButtonWithThrottle.cancel();
    };
  }, [slots, buttonState, friends, user_id, timeDiff, dispatch]);
};
