import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App/App';
import store from './store';
import 'src/i18/index';
import './styles/globals.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', () => {
//     const swUrl = `${process.env.PUBLIC_URL}/serviceWorker.js`
//     navigator.serviceWorker
//       .register(swUrl)
//       .then(registration => {
//         console.log('SW registered: ', registration)
//       })
//       .catch(registrationError => {
//         console.log('SW registration failed: ', registrationError)
//       })
//   })
// }

// if ("serviceWorker" in navigator) {
//     navigator.serviceWorker
//         .register("/serviceWorker.js")
//         .then(function (registration) {
//             console.log("Service worker зарегистрирован:", registration);
//         })
//         .catch(function (error) {
//             console.log("Ошибка при регистрации service worker-а:", error);
//         });
// } else {
//     // Текущий браузер не поддерживает service worker-ы.
//     console.log("Текущий браузер не поддерживает service worker-ы");
// }
