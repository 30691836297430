import { useLocation } from 'react-router-dom';
import { PATHS } from 'routes';
import { TG_WEB_APP } from 'consts';
import bg1 from 'assets/images/officeBg/1.webp';
import bg2 from 'assets/images/officeBg/2.webp';
import bg3 from 'assets/images/officeBg/3.webp';
import bg4 from 'assets/images/officeBg/4.webp';
import bg5 from 'assets/images/officeBg/5.webp';
import bg6 from 'assets/images/officeBg/6.webp';
import bg7 from 'assets/images/officeBg/7.webp';
import bg8 from 'assets/images/officeBg/8.webp';
import bg9 from 'assets/images/officeBg/9.webp';
import commonBg from 'assets/images/commonBackground.webp';
import lockBg from 'assets/images/lockBg.webp';
import { useGetUserData } from './useGetUserData';

const changeBg = (slots) => {
  switch (slots?.length) {
    case 0:
      return bg1;
    case 1:
      return bg1;
    case 2:
      return bg2;
    case 3:
      return bg3;
    case 4:
      return bg4;
    case 5:
      return bg5;
    case 6:
      return bg6;
    case 7:
      return bg7;
    case 8:
      return bg8;
    case 9:
      return bg9;
    default:
      return bg1;
  }
};
export const useBackground = () => {
  const { slots, userFriendSlots } = useGetUserData();
  const viewportHeight = TG_WEB_APP.viewportHeight;
  const { pathname } = useLocation();

  const bgList = {
    [PATHS.OFFICE]: { backgroundImage: `url(${changeBg(slots)})`, backgroundSize: '100%' },
    [PATHS.STAFF_OFFICE]: { backgroundImage: `url(${changeBg(userFriendSlots)})`, backgroundSize: '100%' },
    [PATHS.LOCK]: { backgroundImage: `url(${lockBg}?v=2)`, backgroundSize: '100% 100%' },
    common: { backgroundImage: `url(${commonBg}?!)`, backgroundSize: '100%' },
  };

  const pageBg = () => {
    switch (pathname) {
      case PATHS.OFFICE:
        return bgList[pathname];
      case PATHS.STAFF_OFFICE:
        return bgList[pathname];
      case PATHS.LOCK:
        return bgList[pathname];
      default:
        return bgList.common;
    }
  };
  return {
    pageBackground: pageBg(),
    slotsLength: slots?.length,
    viewportHeight,
  };
};
