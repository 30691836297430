import React, { FC } from 'react';
import cn from 'classnames';
import { Icon } from 'components';
import noWorking from 'assets/images/officeBg/char_1.webp';
import s from './styles.module.scss';

export interface EmptySlotProps {
  classNames?: string;
}

export const ClearSlot: FC<EmptySlotProps> = ({ classNames }) => {
  return (
    <div className={cn(classNames, s.clearState)}>
      <button className={s.clearSlotButton}>
        <Icon iconName={'minus'} />
      </button>

      {/* Изображение неработающего сотрудника */}
      <img src={noWorking} alt="no working employees" />
    </div>
  );
};
