export function formatTime(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;

  // Форматируем минуты и секунды с ведущими нулями
  const formattedMinutes = m.toString().padStart(2, '0');
  const formattedSeconds = Math.floor(s).toString().padStart(2, '0');

  // Если есть часы, добавляем их к формату
  if (h > 0) {
    const formattedHours = h.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
}
