import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TabMenu } from 'feature';
import { CloseButton } from 'pages';
import { marketTabs } from 'consts';
import { PATHS } from 'routes';
import { setFriendsPageActiveTab } from 'store/slices/settingsSlice';
import { useAppDispatch, useSettings, useTutor } from 'src/hooks';
import s from './styles.module.scss';

export const PageFooter = () => {
  const { pathname } = useLocation();
  const { isTutor } = useTutor();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { friendsPageActiveTab } = useSettings();

  const tabMenu = () => {
    switch (pathname) {
      case PATHS.STAFF_OFFICE:
        return <CloseButton />;
      case PATHS.LOCK:
        return null;
      default:
        return <TabMenu />;
    }
  };

  return (
    <div
      className={cn(s.wrapper)}
      style={{ visibility: isTutor ? 'hidden' : 'visible', display: isTutor ? 'none' : '' }}
    >
      {pathname === PATHS.FRIENDS && (
        <div className={s.tabs}>
          {marketTabs?.map((tab) => {
            return (
              <button
                key={tab.id}
                className={cn(s.tab, { [s.active]: friendsPageActiveTab === tab.id })}
                onClick={() => dispatch(setFriendsPageActiveTab(tab.id))}
              >
                <div>{t(tab.text)}</div>
              </button>
            );
          })}
        </div>
      )}
      <div className={s.tabMenuWrapper}>{tabMenu()}</div>
    </div>
  );
};
