import { useState } from 'react';
import { StagesWorkEnum } from '../components';

export const useWorking = () => {
  const [isWorking, setIsWorking] = useState(StagesWorkEnum.NO_WORKING);

  const handleClick = () => {
    setIsWorking(StagesWorkEnum.WORKING);

    setTimeout(() => {
      setIsWorking(StagesWorkEnum.NO_WORKING);
    }, 100);
  };

  return { isWorking, handleClick };
};
