import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Icon } from 'components';
import { useOfficeData, useGetUserData } from 'hooks';
import { PATHS } from 'routes';
import { Buttons } from 'shared/types';
import s from './tabMenu.module.scss';

export const TabMenu: FC = () => {
  const { pathname } = useLocation();
  const { buttonState } = useOfficeData();
  const { hasResoldFriends, isEnergyFull, hasNewInApps, isFreeEnergyBoostActive } = useGetUserData();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tabs = [
    {
      marker: false,
      link: PATHS.FRIENDS,
      image: 'friends',
      label: t('tabMenu.friends'),
    },
    { marker: false, link: PATHS.BOSS, image: 'boss', label: t('tabMenu.boss') },
    {
      marker:
        buttonState === Buttons.CLEM ||
        buttonState === Buttons.START ||
        hasResoldFriends ||
        isEnergyFull ||
        isFreeEnergyBoostActive,
      link: PATHS.OFFICE,
      image: 'office',
      label: t('tabMenu.office'),
    },

    {
      marker: hasNewInApps,
      link: PATHS.SHOP,
      image: 'shop',
      label: t('tabMenu.shop'),
    },
  ];

  return (
    <div className={s.menu}>
      {tabs?.map(({ link, marker, label, image }) => {
        return (
          <div className={s.cardWrapper} key={link} onClick={() => navigate(link)}>
            <div className={cn(s.button, pathname === link && s.active)}>
              {marker && (
                <div className={s.marker}>
                  <Icon iconName={'redMarker'} />
                </div>
              )}

              <div className={s.icon}>
                <Icon iconName={image} />
              </div>
              <span className={s.label}>{label}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
