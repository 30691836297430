import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Icon, OwnerLine } from 'components';
import { useGetOwner, useGetUserData } from 'hooks';
import { abbreviateNumber } from 'shared/helpers/abbreviateNumber';
import { IUserData } from 'shared/types';
import s from './styles.module.scss';

export const BossCard: FC<IUserData> = ({ photo_url, first_name, power, last_name, username, price }) => {
  const { owner_id } = useGetUserData();
  const owner = useGetOwner(owner_id);
  const { t } = useTranslation();

  return (
    <div className={s.bossCard}>
      <div className={s.commonInfoWrapper}>
        <div className={s.avatar}>
          <Avatar image={photo_url} />
        </div>

        <div className={s.infoWrapper}>
          <div className={s.name}>
            {first_name}
            {last_name} {username ? `(${username})` : ''}
          </div>

          <div className={s.powerAndCountWrapper}>
            <div className={s.power}>
              <div>{t('common.power')}</div>

              <div className={s.powerIcon}>
                <Icon iconName={'power'} />
              </div>

              <div className={s.powerCounter}>{abbreviateNumber(power)}</div>
            </div>

            <div className={s.countWrapper}>
              <div>{t('common.hireCost')}</div>

              <div className={s.coinImage}>
                <Icon iconName={'coin'} />
              </div>

              <div>{abbreviateNumber(price)}</div>
            </div>
          </div>
        </div>
      </div>
      <OwnerLine owner={owner} isSmall />
    </div>
  );
};
