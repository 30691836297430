import { FC, useCallback, useRef } from 'react';
import { ClemButton, ModalIdType, StartWorkButton, TapButton } from 'components';
import { useAppDispatch, useGetUserData, useOfficeData } from 'hooks';
import { Buttons, SlotType } from 'shared/types';
import { sendGetWorkRevenue, sendStartWork } from 'store/service/methods';
import { modalIsOpen } from 'store/slices/modalSlice';
import { useCoinsDrop } from 'src/hooks/useCoinsDrop';
import s from './styles.module.scss';

type ButtonPanelProps = {
  onTap: () => void;
};

export const ButtonPanel: FC<ButtonPanelProps> = ({ onTap }) => {
  const { buttonState, isButtonDisabled } = useOfficeData();
  const { slots, user_id, timeDiff } = useGetUserData();
  const dispatch = useAppDispatch();
  const ref = useRef();
  const { generate } = useCoinsDrop({ ref });

  const calculateTotalProfit = useCallback(
    (slots: SlotType[]) => {
      return slots?.reduce((totalProfit, slot) => {
        if (slot.end_time + timeDiff < Date.now() / 1000) {
          return totalProfit + slot.revenue;
        } else {
          return totalProfit;
        }
      }, 0);
    },
    [slots]
  );

  // Запуск работы
  const handleStart = () => {
    // Сотрудника перекупили
    const staffIsLost = slots.some((_) => Boolean(_.friend_owner_id) && _.friend_owner_id !== user_id);
    // Открываем оповещение если сотрудника перекупили
    if (staffIsLost) {
      dispatch(modalIsOpen(ModalIdType.NOTIFICATION));
    }
    sendStartWork(0);
  };

  // Забрать коины
  const handleClem = () => {
    sendGetWorkRevenue(0);
    generate(Math.floor(calculateTotalProfit(slots)));
  };

  const buttons = () => {
    switch (buttonState) {
      case Buttons.START:
        return <StartWorkButton onClick={handleStart} isDisabled={isButtonDisabled} />;
      case Buttons.WORKING:
        return <TapButton onClick={onTap} />;
      case Buttons.CLEM:
        return (
          <ClemButton
            onClick={handleClem}
            isDisabled={isButtonDisabled}
            coins={Math.floor(calculateTotalProfit(slots))}
          />
        );
    }
  };
  return (
    <div className={s.buttonPanel}>
      {buttons()}
      <div ref={ref} className={s.coinsRef}></div>
    </div>
  );
};
