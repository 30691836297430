import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { CountScore, FriendCardShort } from 'feature';
import { useAppDispatch, useGetModalData } from 'hooks';
import { modalIsClose, setNewFriendInfo } from 'store/slices/modalSlice';
import { clearWelcomeBackInfo } from 'store/slices/userSlice';
import s from './styles.module.scss';

export const NewFriendModal = () => {
  const dispatch = useAppDispatch();
  const { newFriendInfo } = useGetModalData();
  const { t } = useTranslation();

  const handleCloseModal = () => {
    dispatch(clearWelcomeBackInfo());
    dispatch(modalIsClose());
    dispatch(setNewFriendInfo(null));
  };

  return (
    <div className={s.welcomeBackModalContent}>
      <h2 className={s.title}>{t('newFriends.title')}</h2>

      <div className={s.notificationWrapper}>
        <h5 className={s.subTitle}>{t('newFriends.subTitle')}</h5>
        <CountScore count={newFriendInfo?.added} />

        <div className={s.notificationContent}>
          <h5 className={s.subTitle}>{t('newFriends.message')}</h5>

          <div className={s.employeesNotification}>
            <FriendCardShort variant="newFriend" data={newFriendInfo} key={newFriendInfo.id} />
          </div>
        </div>
      </div>

      <Button onClick={handleCloseModal}>{t('common.confirm')}</Button>
    </div>
  );
};
