import { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Avatar, ModalIdType } from 'components';
import { Friend, IUserData } from 'shared/types';
import { modalIsOpen, setHireEmployeeModalData } from 'store/slices/modalSlice';
import { useAppDispatch } from 'src/hooks';
import { truncateText } from 'shared/helpers/truncate';
import s from './styles.module.scss';

interface OwnerLineProps {
  owner?: Friend | IUserData;
  isSmall?: boolean;
}

export const OwnerLine: FC<OwnerLineProps> = ({ owner, isSmall }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  if (!owner) return;

  const name = owner?.username
    ? `${owner?.first_name} ${owner?.last_name} ${owner?.username ? `(${owner?.username})` : ''}`
    : `${owner?.first_name} ${owner?.last_name}`;

  const handleClick = () => {
    if ('id' in owner) {
      // Если owner является Friend
      if (owner?.id) {
        dispatch(setHireEmployeeModalData(owner));
        dispatch(modalIsOpen(ModalIdType.HIRE_STAFF));
      }
    }
  };

  return (
    <div
      className={cn(s.ownerWrapper, { [s.small]: isSmall })}
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
    >
      <div className={s.ownerInfoWrapper}>
        <div className={s.ownerText}>{t('common.owner')}:</div>

        <div className={s.ownerAvatar}>
          <Avatar image={owner?.photo_url} variant={'Owner'} />
        </div>

        <div>{truncateText(name, 25)}</div>
      </div>
      <div />
    </div>
  );
};
