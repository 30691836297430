import React, { FC, ReactNode, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import { Loading, ModalWrapper, NotEnoughMoney } from 'components';
import { PageTitle, PageFooter } from 'feature';
import { useBackground, useGetUserData, useTutor } from 'hooks';
import { PATHS } from 'routes';
import s from './styles.module.scss';

interface PageLayoutProps {
  children: ReactNode;
}

export const Layout: FC<PageLayoutProps> = ({ children }) => {
  const { pathname } = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  const { pageBackground } = useBackground();
  const { user } = useGetUserData();
  const { isTutor } = useTutor();
  const isErrorPage = pathname === PATHS.ERROR;

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
    }
  }, [pathname]);

  return !isErrorPage ? (
    <>
      <Loading />
      <NotEnoughMoney />

      <div className={cn(s.appWrapper, { [s.hidden]: !user })} style={pageBackground}>
        <ModalWrapper />
        <PageTitle />

        <div
          className={s.pages}
          ref={ref}
          style={{ overflowY: isTutor || pathname === PATHS.OFFICE ? 'hidden' : 'auto' }}
        >
          {children}
        </div>

        <PageFooter />
      </div>
    </>
  ) : (
    <>{children}</>
  );
};
