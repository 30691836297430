import { ShopCard } from 'feature';
import { useGetUserData, useLocalStorage } from 'hooks';
import { sendPurchase } from 'store/service/methods';
import s from './styles.module.scss';

const ShopPage = () => {
  const { in_apps } = useGetUserData();
  const { get, set } = useLocalStorage();

  const inAppsLocal: { id: number; viewed: boolean }[] = get('in_apps', []);

  const changeViewed = (id: number) => {
    const newState = inAppsLocal?.map((card) => {
      if (card.id === id) {
        return { ...card, viewed: true };
      }
      return card;
    });

    set('in_apps', newState);
  };

  return (
    <div className={s.wrapper}>
      <div className={s.pageTitle}>COINS</div>

      <div className={s.cardsWrapper}>
        {in_apps?.map((card) => (
          <div key={card.id}>
            <ShopCard
              {...card}
              onClick={() => {
                sendPurchase(card.id);
                changeViewed(card.id);
              }}
              marker={!inAppsLocal.find((_) => _.id === card.id).viewed}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopPage;
