import { FC, PointerEvent, useEffect, useState } from 'react';
import cn from 'classnames';
import { EnergyLimit, Icon, Marker, ModalIdType } from 'components';
import { useAppDispatch, useGetUserData } from 'hooks';
import { sendClick } from 'store/service/methods';
import { modalIsOpen } from 'store/slices/modalSlice';
import { updateAfterDebouncedClick } from 'store/slices/userSlice';
import { NoEnergy } from './NoEnergy';
import { TapCoin } from './TapCoin';
import s from './styles.module.scss';

interface Click {
  id: number;
  x: number;
  y: number;
  energyEnough: boolean;
}

// Расширяем интерфейс Window для добавления webkit
declare global {
  interface Window {
    webkit: any;
  }
}

interface TapButtonProps {
  onClick?: () => void;
}

export const TapButton: FC<TapButtonProps> = ({ onClick }) => {
  const { user, energy, isEnergyFull, skill_levels } = useGetUserData();
  const dispatch = useAppDispatch();
  const { max_energy, skill_values } = user || {};
  const [clicks, setClicks] = useState<Click[]>([]); // Состояние для хранения кликов
  const [lastClickTime, setLastClickTime] = useState<number>(0); // Состояние для хранения времени последнего клика
  const [activePointers, setActivePointers] = useState<number>(0); // Состояние для хранения количества активных указателей
  const [clickCount, setClickCount] = useState<number>(1); // Счетчик кликов
  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null); // Таймер для дебаунса

  const handleDebauncedClick = () => {
    // Увеличиваем счетчик кликов
    setClickCount((prevCount) => ++prevCount);
    // Обновляем деньги и энергию при клике
    dispatch(updateAfterDebouncedClick());

    // Сбрасываем таймер, если он уже запущен
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Запускаем новый таймер на 1 секунду
    setDebounceTimeout(
      setTimeout(() => {
        sendClick(clickCount); // Отправляем запрос с количеством кликов
        setClickCount(1); // Сбрасываем счетчик
      }, 1000)
    );
  };

  // Обработчик для PointerDown события
  const handlePointerDown = (event: PointerEvent<HTMLButtonElement>) => {
    const rect = event.currentTarget.getBoundingClientRect(); // Получаем координаты кнопки
    const x = event.clientX - rect.left; // Рассчитываем координаты клика
    const y = event.clientY - rect.top;

    setClicks((prevClicks) => {
      const newClick: Click = {
        id: Date.now(), // Уникальный идентификатор для клика
        x,
        y,
        energyEnough: energy >= skill_values['clickerman'],
      };
      const updatedClicks = [newClick, ...prevClicks].slice(0, 10); // Ограничиваем количество кликов до 10
      return updatedClicks;
    });

    if (user.energy >= skill_values['clickerman']) {
      handleDebauncedClick();
      setLastClickTime(Date.now()); // Обновляем время последнего клика
      setActivePointers((prevActivePointers) => prevActivePointers + 1); // Увеличиваем количество активных указателей
      onClick();

      // Добавляем виброотклик для Android
      if (navigator.vibrate) {
        navigator.vibrate(50); // Вибрация на 50 миллисекунд
      }

      // Добавляем виброотклик для iOS
      const isIos = /iPad|iPhone|iPod/.test(navigator.userAgent);
      if (isIos && window.webkit?.messageHandlers?.vibrate) {
        window.webkit.messageHandlers.vibrate.postMessage(50);
      }
    }
  };

  // Обработчик для PointerUp события
  const handlePointerUp = () => {
    setActivePointers((prevActivePointers) => Math.max(prevActivePointers - 1, 0)); // Уменьшаем количество активных указателей, не опускаясь ниже 0
  };
  // ОТкрытие модалки рефреша энергии
  const handleClickRefreshEnergy = () => {
    dispatch(modalIsOpen(ModalIdType.REFRESH_ENERGY));
  };

  // Эффект для удаления старых кликов через 300 мс
  useEffect(() => {
    const timer = setInterval(() => {
      if (Date.now() - lastClickTime > 300) {
        setClicks((prevClicks) => prevClicks.slice(0, -1)); // Удаляем последний клик, если прошло больше 300 мс
      }
    }, 1000);

    return () => clearInterval(timer); // Очищаем таймер при размонтировании
  }, [lastClickTime]);

  return (
    <div className={s.wrapper}>
      <div className={s.energyLimit} onClick={handleClickRefreshEnergy}>
        {isEnergyFull && <Marker classNames={s.marker} />}
        <EnergyLimit energy={energy} energyLimit={max_energy!} />
      </div>

      <div className={s.buttonWrapper}>
        <button
          className={cn(s.button, {
            [s.active]: activePointers > 0,
            [s.notEnough]: energy < skill_levels?.['clickerman'],
          })} // Условно добавляем класс active
          onPointerDown={handlePointerDown} // Добавляем обработчик на PointerDown
          onPointerUp={handlePointerUp} // Добавляем обработчик на PointerUp
          onPointerCancel={handlePointerUp} // Обрабатываем отмену указателя
          onPointerLeave={handlePointerUp} // Обрабатываем выход указателя за пределы элемента
        >
          <div className={s.coin}>
            <div className={s.buttonRing}>
              <div className={s.tapButton}>
                <Icon iconName={'money'} size={[110, 110]} />
              </div>
            </div>
          </div>
          <div>
            {clicks?.map((click) => (
              <div
                key={click.id}
                className={s.click}
                style={{ left: click?.energyEnough ? click.x : '25%', top: click.y }}
              >
                <>
                  <TapCoin tapSpeed={skill_values['clickerman']} visibility={click?.energyEnough} />
                  <NoEnergy visibility={!click?.energyEnough} />
                </>
              </div>
            ))}
          </div>
        </button>
      </div>
    </div>
  );
};
