import { TypedUseSelectorHook, useDispatch, useSelector as useReduxSelector } from 'react-redux';
import store from 'src/store';

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();

// reexport
export * from './useGetUserData';
export * from './useWorking';
export * from './useNotification';
export * from './useBackground';
export * from './useTutor';
export * from './useGetModalData';
export * from './useLeavePage';
export * from './useTimeLeft';
export * from './useUpdateOffice';
export * from './useLocalStorage';
export * from './useAuthorization';
export * from './useSideEffects';
export * from './useSettings';
export * from './useOfficeData';
export * from './useGetOwner';
