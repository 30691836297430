import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Marker } from 'components';
import s from './styles.module.scss';

interface TapButtonProps {
  onClick?: () => void;
  isDisabled?: boolean;
}

export const StartWorkButton: FC<TapButtonProps> = ({ onClick, isDisabled }) => {
  const { t } = useTranslation();

  return (
    <div className={s.wrapper}>
      <div className={s.buttonWrapper}>
        <button className={s.button} disabled={isDisabled} onClick={onClick}>
          <Marker classNames={s.marker} />
          <div className={s.coin}>
            <div className={s.buttonRing}>
              <div className={s.tapButton}>
                <div className={s.icon}>
                  <Icon iconName={'rocket'} size={[35, 35]} />
                </div>
                <div className={s.text}>{t('common.startWork')}</div>
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};
