import { useTranslation } from 'react-i18next';
import { initData } from 'consts';
import { Button } from 'src/components';
import { authenticate } from 'store/service';
import { modalIsClose } from 'store/slices/modalSlice';
import { deviceId, useAppDispatch } from 'src/hooks';
import s from './styles.module.scss';

export const NewConnectionModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(modalIsClose());
    authenticate(initData, deviceId);
  };

  return (
    <div className={s.wrapper}>
      <p>{t('newConnection')}</p>
      <Button onClick={handleClick} variant="Blue">
        {t('reconnectText')}
      </Button>
    </div>
  );
};
