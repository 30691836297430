import { FC, PointerEvent, ReactNode } from 'react';
import cn from 'classnames';
import s from './styles.module.scss';

interface UserCardsWrapperProps {
  children: ReactNode;
  classNames?: string;
  rating?: number;
  onClick?: (event: PointerEvent<HTMLButtonElement>) => void;
}

export const UserCardsWrapper: FC<UserCardsWrapperProps> = ({ children, classNames, onClick, rating }) => {
  return (
    <button onClick={onClick}>
      <div className={cn(s.cardsWrapper, classNames)}>
        {rating && (
          <div className={cn(s.ratingBorder, s[`top_${rating}_border`])}>
            <div
              className={cn(s.rating, s[`top_${rating}`])}
              style={{ fontSize: rating.toString().length > 2 ? '10px' : '16px' }}
            >
              {rating}
            </div>
          </div>
        )}
        {children}
      </div>
    </button>
  );
};
