import React from 'react';
import { useTranslation } from 'react-i18next';
import errorBg from 'assets/images/commonBackground.webp';

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <div style={{ backgroundImage: errorBg, backgroundSize: 'cover', backgroundRepeat: 'no-repeat' }}>
      <h1>404 - {t('errorPage.title')}</h1>
    </div>
  );
};

export default NotFoundPage;
