import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { INotificationType } from 'shared/types';
import { useSettings } from 'hooks';
import s from './styles.module.scss';

interface NotEnoughMoneyProps {
  text?: string;
}

type MessageType = { [key in INotificationType]: string };

// Тексты оповещений
const notificationTexts: MessageType = {
  [INotificationType.NO_ENOUGH_MONEY]: 'notification.notEnoughMoney',
  [INotificationType.WORK_NOT_COMPLETED]: 'notification.workNotComplete',
  [INotificationType.TAKE_YOU_INCOME]: 'notification.takeYourIncome',
};

export const NotEnoughMoney: FC<NotEnoughMoneyProps> = () => {
  const { t } = useTranslation();
  const { notification } = useSettings();

  return (
    <div className={s.wrapper}>
      <div
        className={cn(s.text, { [s.textVisible]: notification?.position })}
        style={{ top: `${notification?.position}px` }}
      >
        {t(notificationTexts[notification?.text])}
      </div>
    </div>
  );
};
