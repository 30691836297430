import { configureStore, combineReducers } from '@reduxjs/toolkit';
import modalSlice from './slices/modalSlice';
import officeSlice from './slices/officeSlice';
import settingsSlice from './slices/settingsSlice';
import userSlice from './slices/userSlice';

// Создание rootReducer
const rootReducer = combineReducers({
  modal: modalSlice,
  user: userSlice,
  settings: settingsSlice,
  office: officeSlice,
});

// Конфигурация store
const store = configureStore({
  reducer: rootReducer,
});

export default store;
