import { useAppSelector, useGetUserData } from './index';

export const useGetModalData = () => {
  const modalData = useAppSelector((state) => state.modal);
  const { money } = useGetUserData();

  const isMoneyEnough = modalData?.newFriendInfo?.price <= money;

  return { isMoneyEnough, ...modalData };
};
