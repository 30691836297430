import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components';
import s from './styles.module.scss';

interface NoEnergyProps {
  visibility: boolean;
}
export const NoEnergy: FC<NoEnergyProps> = ({ visibility }) => {
  const { t } = useTranslation();

  return (
    <div className={s.noEnergy} style={{ visibility: visibility ? 'visible' : 'hidden' }}>
      <Icon iconName={'lightning'} size={[30, 30]} />
      {t('notification.noEnergy')}
    </div>
  );
};
