import { FC } from 'react';
import cn from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { inviteLink } from 'consts';
import { Button, Icon, InviteFriends } from 'components';
import { useAppDispatch, useGetUserData } from 'hooks';
import { PATHS } from 'routes';
import { setLockVisited } from 'store/slices/settingsSlice';
import s from './styles.module.scss';

export interface InviteFriendProps {
  classNames?: string;
  isContinueButton?: boolean;
}

export const InviteFriend: FC<InviteFriendProps> = ({ classNames, isContinueButton }) => {
  const { t } = useTranslation();
  const { user_id } = useGetUserData();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <div className={cn(s.wrapper, classNames)}>
      <InviteFriends link={inviteLink(user_id).copyLink} isContinueButton={isContinueButton} />

      <div className={s.addUserButton}>
        {isContinueButton ? (
          <Button
            variant="Green"
            onClick={() => {
              dispatch(setLockVisited());
              navigate(PATHS.OFFICE);
            }}
          >
            {t('common.continue')}
          </Button>
        ) : (
          <Link to={inviteLink(user_id).link}>
            <Button onClick={() => {}} variant={'Blue'}>
              <div className={s.buttonContentWrapper}>
                <div>{t('inviteFriends.inviteButton')}</div>

                <div className={s.addUserIcon}>
                  <Icon iconName={'addUser'} />
                </div>
              </div>
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
};
