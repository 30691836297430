import { FC, PointerEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Icon } from 'components';
import { useAppDispatch, useGetModalData, useGetUserData, useNoEnoughMoney } from 'hooks';
import { PATHS } from 'routes';
import { Friend, INotificationType } from 'shared/types';
import { modalIsClose, setHireEmployeeModalData, setHireEmployeeOfficeData } from 'store/slices/modalSlice';
import { FriendCardDetails } from 'feature';
import { buyFriend } from 'store/service/methods';
import s from './styles.module.scss';

interface HireEmployeeProps {
  data: Friend;
}

export const HireEmployee: FC<HireEmployeeProps> = ({ data }) => {
  const dispatch = useAppDispatch();
  const { hireEmployeeModalData } = useGetModalData();
  const { handleClick } = useNoEnoughMoney();
  const navigate = useNavigate();
  const { user_id, money } = useGetUserData();
  const isMoneyEnough = data?.price <= money;
  const { t } = useTranslation();

  const handlePushEmployeeOffice = () => {
    if (data.id === user_id) {
      navigate(PATHS.OFFICE);
      dispatch(modalIsClose());
      return;
    }
    dispatch(setHireEmployeeOfficeData(hireEmployeeModalData));
    navigate(PATHS.STAFF_OFFICE);
    dispatch(modalIsClose());
  };

  //Хендлер нанять сотрудника
  const hireStaff = (event: PointerEvent<HTMLButtonElement>) => {
    // Если денег недостаточно выводим оповещалку
    if (!isMoneyEnough) {
      handleClick({ event, textType: INotificationType.NO_ENOUGH_MONEY });
      return;
    }
    // Если являемся его владельцем
    if (data.id === user_id) {
      return;
    }
    // Успешная покупка сотрудника
    buyFriend(data.id, data.price);
    dispatch(setHireEmployeeModalData({ ...hireEmployeeModalData, owner_id: user_id }));
  };

  if (!data) {
    return;
  }

  return (
    <div className={s.wrapper}>
      <h4>{t('common.info')}</h4>

      <FriendCardDetails friend={hireEmployeeModalData} />

      <div className={s.buttonWrapper}>
        <Button onClick={handlePushEmployeeOffice} variant={'Blue'} classNames={s.button}>
          {t('common.viewOffice')}
        </Button>

        {data.owner_id !== user_id && data.id !== user_id && (
          <Button onClick={hireStaff} variant={'Blue'} classNames={s.button}>
            <div className={s.hireButtonContent}>
              <div>{t('common.hire')}</div>

              <div className={s.priceWrapper}>
                <div className={s.iconWrapper}>
                  <Icon iconName={'coin'} />
                </div>
                <div className={isMoneyEnough ? s.textGold : s.textRed}>{data.price}</div>
              </div>
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};
