import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotification, LanguagesEnum } from 'shared/types';

interface InitialState {
  language: LanguagesEnum;
  tutorStep: number | null;
  viewStaffInfo: boolean;
  isTutor: boolean;
  notification: INotification | null;
  lockVisited: boolean;
  friendsPageActiveTab: number;
  errorLogs: string[] | null;
}

const initialState: InitialState = {
  language: LanguagesEnum.EN,
  tutorStep: 0,
  viewStaffInfo: false,
  isTutor: false,
  notification: null,
  lockVisited: false,
  friendsPageActiveTab: 1,
  errorLogs: null,
};

const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState,
  reducers: {
    // Выбор языка
    changeLanguage: (state, action: PayloadAction<LanguagesEnum>) => {
      state.language = action.payload;
    },
    // Следующий шаг тутора
    changeNextStepTutor: (state, action: PayloadAction<number>) => {
      state.tutorStep = action.payload;
    },
    // Открыть просмотр информации о сотрудниках
    viewStaffInfoAction: (state, action: PayloadAction<boolean>) => {
      state.viewStaffInfo = action.payload;
    },
    // Изменение состояния отображения тутора
    changeIsTutor: (state, action: PayloadAction<boolean>) => {
      state.isTutor = action.payload;
    },
    // Отображение нотификации в определенной позиции
    onNotification: (state, action: PayloadAction<INotification>) => {
      state.notification = action.payload;
    },
    // Индикатор посещения страницы лока
    setLockVisited: (state) => {
      state.lockVisited = true;
    },
    // Изменение активного таба на странице friends
    setFriendsPageActiveTab: (state, action) => {
      state.friendsPageActiveTab = action.payload;
    },
    setErrorLogs: (state, action) => {
      state.errorLogs = [...(state.errorLogs || []), action.payload];
    },
  },
});

export const {
  changeLanguage,
  changeNextStepTutor,
  viewStaffInfoAction,
  changeIsTutor,
  onNotification,
  setLockVisited,
  setFriendsPageActiveTab,
  setErrorLogs,
} = settingsSlice.actions;

export default settingsSlice.reducer;
