import { useTranslation } from 'react-i18next';
import store from 'store/index';
import { helpIsOpen } from 'store/slices/modalSlice';
import { Icon } from 'src/components';
import { useGetModalData } from 'hooks';
import s from './styles.module.scss';

const helps = {
  resell: `helps.resell`,
};
export type HelpsType = keyof typeof helps;

export const openHelpModal = (helpId: HelpsType) => store.dispatch(helpIsOpen(helpId));

export const HelpModal = () => {
  const { helpId } = useGetModalData();
  const { t } = useTranslation();

  return (
    <div className={s.wrapper}>
      <Icon iconName={'info'} size={[30, 30]} />
      <p>{t(helps[helpId])}</p>
    </div>
  );
};
