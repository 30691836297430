import React, { FC } from 'react';
import { TitleWrapper, Icon } from 'components';
import s from './styles.module.scss';

interface CountContainerProps {
  count: number;
}

export const CountScore: FC<CountContainerProps> = ({ count }) => {
  return (
    <TitleWrapper>
      <div className={s.scoreWrapper}>
        <div className={s.iconWrapper}>
          <Icon iconName={'coin'} />
        </div>

        <h4 className={s.coinScore}>{count ? Number(Math.floor(count)).toFixed(0) : ''}</h4>
      </div>
    </TitleWrapper>
  );
};
