import { PointerEvent, useRef } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'components';
import { FriendCardShort } from 'feature';
import { useAppDispatch, useGetUserData, useNoEnoughMoney, useTimeLeft } from 'hooks';
import { abbreviateNumber } from 'shared/helpers/abbreviateNumber';
import { buyFriend, sendGetWorkRevenue, sendManageSlot, sendStartWork } from 'store/service/methods';
import { modalIsClose } from 'store/slices/modalSlice';
import { setDeleteFriendData, setFriends } from 'store/slices/userSlice';
import { Friend, INotificationType, SlotType } from 'shared/types';
import { useCoinsDrop } from 'src/hooks/useCoinsDrop';
import s from './styles.module.scss';

const useDeleteFriendHandlers = (
  friend: Friend,
  deleteFriendInfo: SlotType,
  money: number,
  friends: Friend[],
  user_id: number
) => {
  const dispatch = useAppDispatch();

  const { handleClick } = useNoEnoughMoney();

  const handleDelete = () => {
    // Забирание дохода с работника
    sendGetWorkRevenue(deleteFriendInfo.slotId + 1);
    // Успешное удаление
    sendManageSlot(deleteFriendInfo.slotId, 0);
    setTimeout(() => {
      dispatch(modalIsClose());
    });
  };

  const hireStaff = (event: PointerEvent<HTMLButtonElement>) => {
    if (friend.price > money) {
      handleClick({ event, textType: INotificationType.NO_ENOUGH_MONEY });
      return;
    }

    // Успешноя покупка
    buyFriend(friend.id, friend.price);
    dispatch(setDeleteFriendData({ ...deleteFriendInfo, friend_owner_id: user_id }));
    dispatch(
      setFriends({
        friends: friends?.map((mapFriend) =>
          mapFriend.id === friend.id ? { ...mapFriend, owner_id: user_id } : mapFriend
        ),
      })
    );
  };

  return { handleDelete, hireStaff };
};

export const DeleteFriendModal = () => {
  const { t } = useTranslation();
  const ref = useRef();
  const { generate } = useCoinsDrop({ ref });

  const { friends, timeDiff, deleteFriendInfo, user_id, money } = useGetUserData();
  if (!deleteFriendInfo) return null;

  const friend = friends?.find((f) => f.id === deleteFriendInfo.friend_id);
  const working = deleteFriendInfo.end_time + timeDiff >= Date.now() / 1000;
  const isSold = friend?.owner_id !== user_id;
  const timeLeft = useTimeLeft(deleteFriendInfo.end_time, null);
  const isMoneyEnough = friend?.price <= money;
  const { handleDelete, hireStaff } = useDeleteFriendHandlers(friend, deleteFriendInfo, money, friends, user_id);

  const renderActionButton = () => {
    if (isSold) {
      return (
        <Button onClick={hireStaff} variant="Blue" classNames={s.button}>
          <div className={s.hireButtonContent}>
            <div>{t('common.hire')}</div>
            <div className={s.priceWrapper}>
              <div className={s.iconWrapper}>
                <Icon iconName="coin" />
              </div>
              <div className={isMoneyEnough ? s.textGold : s.textRed}>{friend.price}</div>
            </div>
          </div>
        </Button>
      );
    }

    if (!working && deleteFriendInfo.revenue) {
      return (
        <Button
          variant="Green"
          onClick={() => {
            sendGetWorkRevenue(deleteFriendInfo.slotId + 1);
            generate(deleteFriendInfo.revenue);
          }}
          classNames={cn(s.button, s.takeIncome)}
        >
          <div>{t('common.takeIncome')}</div>
          <div className={s.revenue}>
            <Icon iconName="coin" size={[15, 15]} /> {abbreviateNumber(deleteFriendInfo.revenue)}
          </div>
        </Button>
      );
    }

    if (working) {
      return (
        <Button variant="Blue" onClick={() => {}} classNames={cn(s.button, s.working)}>
          {t('common.working')} <Icon iconName="clock" size={[15, 15]} /> {timeLeft}
        </Button>
      );
    }

    return (
      <Button
        variant="Blue"
        onClick={() => sendStartWork(deleteFriendInfo.slotId + 1)}
        classNames={cn(s.button, s.start)}
      >
        <Icon iconName="rocket" size={[15, 15]} />
        {t('common.startWork')}
      </Button>
    );
  };

  return (
    <div className={s.welcomeBackModalContent}>
      <div className={s.notificationContent}>
        <h5 className={s.title}>{t('common.info')}</h5>
        <div className={s.employeesNotification}>
          <FriendCardShort data={friend} />
        </div>
      </div>
      <div className={s.buttons}>
        <Button onClick={() => handleDelete()} variant="Red" classNames={s.button}>
          {t('deleteFriend.remove')}
        </Button>

        <div className={s.actionBtnWrapper}>
          {renderActionButton()}
          <div ref={ref} className={s.coinsRef}></div>
        </div>
      </div>
    </div>
  );
};
