import React, { FC } from 'react';
import cn from 'classnames';
import { useGetUserData } from 'hooks';
import loadCircle from 'assets/images/load_circle.webp';
import s from './styles.module.scss';

export interface LoadingProps {
  classNames?: string;
}

export const Loading: FC<LoadingProps> = ({ classNames }) => {
  const { user } = useGetUserData();

  return (
    <div className={cn(classNames)}>
      <div className={cn(s.loading, { [s.hidden]: user })}>
        <div className={s.circle}>
          <img src={loadCircle} alt={'loading circle'} />
        </div>
      </div>
    </div>
  );
};
