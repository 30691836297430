import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { FriendCardShort } from 'feature';
import { useAppDispatch, useGetUserData } from 'hooks';
import { modalIsClose } from 'store/slices/modalSlice';
import s from './styles.module.scss';

export const SellFriendModal = () => {
  const dispatch = useAppDispatch();
  const { friends, sellFriendInfo } = useGetUserData();
  const { t } = useTranslation();

  const handleCloseModal = () => {
    dispatch(modalIsClose());
  };

  if (!sellFriendInfo) return;

  return (
    <div className={s.welcomeBackModalContent}>
      <div className={s.notificationContent}>
        <h5 className={s.title}>Your friend was bought out!</h5>

        <FriendCardShort data={friends.find((_) => _.id === sellFriendInfo.friend_id)} />
      </div>

      <Button onClick={handleCloseModal}>{t('common.confirm')}</Button>
    </div>
  );
};
