import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiendsListFilter } from 'components';
import { InviteFriend, FriendCard } from 'feature';
import { useGetUserData, useSettings } from 'hooks';
import { Friend } from 'shared/types';
import s from './styles.module.scss';

const FriendsPage = () => {
  const { t } = useTranslation();
  const [isSelectedFilter, setIsSelectedFilter] = useState<number>(1);
  const { friends, user_id, friends_friends, top_players, new_players } = useGetUserData();
  const { friendsPageActiveTab } = useSettings();
  const notMyFriendsTab = [2, 3, 4].includes(friendsPageActiveTab);

  const handleFiltered = (friends: Friend[]) => {
    return friends.filter((friend) => {
      if (isSelectedFilter === 1 || notMyFriendsTab) return true;
      if (isSelectedFilter === 2) return friend?.owner_id === user_id;
      if (isSelectedFilter === 3) return friend?.owner_id !== user_id;
    });
  };

  const tabsContent = {
    1: friends,
    2: friends_friends,
    3: new_players,
    4: top_players,
  };

  return (
    <div className={s.wrapper}>
      {!notMyFriendsTab && (
        <>
          <InviteFriend />

          <div className={s.filters}>
            <FiendsListFilter isSelected={isSelectedFilter} onSelected={(value) => setIsSelectedFilter(value)} />
          </div>
        </>
      )}

      <div className={s.cardsWrapper}>
        {tabsContent[friendsPageActiveTab]?.length
          ? handleFiltered(tabsContent[friendsPageActiveTab])
              .sort((a, b) => {
                if (friendsPageActiveTab === 3) {
                  return 1;
                }
                return b.power - a.power;
              })
              ?.map((friend, index) => {
                return (
                  <FriendCard
                    rating={friendsPageActiveTab === 4 && index + 1}
                    {...friend}
                    key={friend.id}
                    friend={friend}
                  />
                );
              })
          : t('inviteFriends.noFriends')}
      </div>
    </div>
  );
};

export default FriendsPage;
