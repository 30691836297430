import React, { FC, PointerEvent, ReactNode } from 'react';
import cn from 'classnames';
import { Icon } from 'components';
import s from './styles.module.scss';

interface UpdateCardsWrapperProps {
  noEnoughMoney?: boolean;
  price: number;
  marker?: ReactNode;
  header: string;
  main: ReactNode;
  footer: ReactNode;
  onClick?: (event: PointerEvent<HTMLButtonElement>) => void;
  noEnoughLabel?: boolean;
  classNames?: string;
}
export const UpdateCardsWrapper: FC<UpdateCardsWrapperProps> = ({
  marker,
  header,
  main,
  footer,
  onClick,
  classNames,
}) => {
  return (
    <button onClick={onClick} className={s.upgradeCardButton}>
      <div className={cn(s.updatedCardsWrapper, classNames)}>
        {marker && <>{marker}</>}
        <div className={s.header}>{header}</div>

        <div className={s.cardBody}>
          <div className={s.main}>{main}</div>
          <Icon iconName={'divider'} classNames={s.divider} />
          <div className={s.footer}>{footer}</div>
        </div>
      </div>
    </button>
  );
};
