import { FC } from 'react';
import cn from 'classnames';
import { ModalIdType } from 'components';
import { useAppDispatch, useGetUserData, useTutor } from 'hooks';
import { Friend, SlotType } from 'shared/types';
import { modalIsOpen, setAddStaffData, setHireEmployeeModalData } from 'store/slices/modalSlice';
import { setDeleteFriendData } from 'store/slices/userSlice';
import { changeIsTutor } from 'store/slices/settingsSlice';
import noWorking from 'assets/images/officeBg/char_1.webp';
import working from 'assets/images/officeBg/char_2.webp';
import { ClearSlot } from './ClearSlot/ClearSlot';
import { EmptySlot } from './EmptySlot/EmptySlot';
import { StaffInfo } from './StaffInfo/StaffInfo';
import s from './styles.module.scss';

// Определение возможных состояний работы сотрудника
export enum StagesWorkEnum {
  WORKING = 'working',
  NO_WORKING = 'noWorking',
}

// Интерфейс для свойств компонента Slot
interface EmployeesImageProps extends SlotType {
  isWorking?: StagesWorkEnum; // Пропс для состояния работы
  slotIndex?: number;
  isFriendsTables?: boolean;
  friendsFriend?: Friend;
  slot: SlotType;
  classNames?: string;
}

export const Slot: FC<EmployeesImageProps> = ({
  isWorking,
  friend_id,
  friend_owner_id,
  start_time,
  end_time,
  slotIndex,
  isFriendsTables,
  friendsFriend,
  slot,
  classNames,
}) => {
  const dispatch = useAppDispatch();
  const { isTutor } = useTutor();

  // Получение данных о пользователе и друзьях
  const { user_id } = useGetUserData();

  // Проверка, свободен ли слот (нет сотрудника)
  const isFree = friend_id === 0;

  // Проверка, принадлежит ли слот другому пользователю
  const isSold = Boolean(friend_owner_id) && friend_owner_id !== user_id;

  // Проверка, не работает ли сотрудник (нет времени начала и конца работы)
  const isNoWorkingEmployee = end_time === 0 && start_time === 0;

  // Проверка на то что сотрудник друга перекуплен
  const isEmptySlot = slot?.friend_id === 0;

  // Функция для рендеринга состояния работы сотрудника
  const staffWork = () => {
    return (
      <>
        {/* Изображение работающего сотрудника */}
        <img
          src={working}
          className={isWorking === StagesWorkEnum.WORKING ? s.visible : s.hidden}
          alt="working employees"
        />
        {/* Изображение неработающего сотрудника */}
        <img
          src={noWorking}
          className={isWorking === StagesWorkEnum.NO_WORKING ? s.visible : s.hidden}
          alt="no working employees"
        />
      </>
    );
  };

  // Если это оффис друга, добавляем возможность перекупить сотрудника
  const handleHireFriendsFriend = () => {
    if (isFriendsTables) {
      dispatch(setHireEmployeeModalData(friendsFriend));
      dispatch(modalIsOpen(ModalIdType.HIRE_STAFF));
    }
  };

  // Рендер сотрудника друга
  const renderFriendSlot = () => {
    if (isEmptySlot) return <EmptySlot withButton={false} />;

    return (
      <div className={s.slotWrapper} onClick={handleHireFriendsFriend}>
        <StaffInfo isFriendsTables={isFriendsTables} friendsFriend={friendsFriend} />
        {staffWork()}
      </div>
    );
  };

  const handleClickSlot = () => {
    if (isTutor) {
      dispatch(changeIsTutor(false));
      return;
    }
    // Открытие модального окна добавления сотрудника
    if (isFree) {
      dispatch(modalIsOpen(ModalIdType.ADD_STAFF));
      dispatch(setAddStaffData({ slot_id: slotIndex }));
      return;
    }
    // Удаление работника из слота
    if (friend_id || isFriendsTables) {
      dispatch(setDeleteFriendData({ ...slot, slotId: slotIndex }));
      dispatch(modalIsOpen(ModalIdType.DELETE_FRIEND));
    }
  };

  return !isFriendsTables ? (
    <div className={cn(s.slotWrapper, isTutor ? s.tutor : '', classNames)} onClick={handleClickSlot}>
      {/* Если слот продан другому пользователю и это не отображается на столе друга,*/}
      {/* отображаем кнопку для очистки слота (удаления сотрудника).*/}
      {/* {isSold && <ClearSlot />} */}
      <ClearSlot classNames={isSold ? s.visible : s.hidden} />

      {/* Если слот свободен (в нем нет сотрудника) и это не стол друга,*/}
      {/* отображаем кнопку для добавления сотрудника в этот слот.*/}
      <EmptySlot classNames={isFree ? s.visible : s.hidden} />

      {/*Если слот занят (в нем есть сотрудник), отображаем информацию о сотруднике.*/}
      <StaffInfo
        isSold={isSold}
        isStaffWork={!isFriendsTables && !isNoWorkingEmployee}
        friend_id={friend_id}
        isFriendsTables={isFriendsTables}
        friendsFriend={friendsFriend}
        end_time={end_time}
        start_time={start_time}
        classNames={!isFree ? s.visible : s.hidden}
      />

      <div className={!isSold && !isFree ? s.visible : s.hidden}>{staffWork()}</div>
    </div>
  ) : (
    renderFriendSlot()
  );
};
