import { FC } from 'react';
import cn from 'classnames';
import { Icon } from 'components';
import { useTutor, useGetUserData } from 'hooks';
import tutorImage from 'assets/images/tutor.webp';
import s from './styles.module.scss';

export interface TutorProps {
  classNames?: string;
}

export const Tutor: FC<TutorProps> = ({ classNames }) => {
  const { tutorText, step, onNextStep, isTutor } = useTutor();
  const { user } = useGetUserData();

  const step0 = () => {
    return (
      <div className={cn(s.step, { [s.stepHidden]: step !== 0 })} onClick={() => onNextStep(1)}>
        <Icon iconName={'plus'} />
      </div>
    );
  };

  return (
    <div className={cn(s.tutorOverlay, { [s.tutorDisable]: !isTutor }, classNames)}>
      <div className={s.tutor}>
        {step0()}

        <div className={cn(s.tutorImage, user ? s.tutorImageView : '')}>
          <img src={tutorImage} alt="" />
        </div>

        <div className={s.tutorText}>{tutorText}</div>
      </div>
    </div>
  );
};
