import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { useAppDispatch } from 'hooks';
import { modalIsClose } from 'store/slices/modalSlice';
import s from './styles.module.scss';

export const DeleteAccModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleCancel = () => {
    dispatch(modalIsClose());
  };

  const handleDelete = () => {};

  return (
    <div className={s.wrapper}>
      <h2 className={s.title}>{t('deleteAccount.title')}</h2>
      <h3 className={s.title}>{t('deleteAccount.subTitle')}</h3>

      <div className={s.buttonWrapper}>
        <Button onClick={handleCancel} variant={'Blue'}>
          {t('common.cancel')}
        </Button>

        <Button onClick={handleDelete} variant={'Red'}>
          {t('common.delete')}
        </Button>
      </div>
    </div>
  );
};
