import { MouseEvent, PointerEvent } from 'react';
import { onNotification } from 'store/slices/settingsSlice';
import { useAppDispatch } from 'hooks';
import { INotificationType } from 'shared/types';

interface UseNotification {
  event: PointerEvent<HTMLButtonElement> | MouseEvent<HTMLDivElement>;
  textType?: INotificationType;
}

export const useNoEnoughMoney = () => {
  const dispatch = useAppDispatch();
  let timeoutId: NodeJS.Timeout | null = null; // Хранение таймера
  let lastClickTime: number | null = null; // Время последнего клика

  const handleClick = ({ event, textType }: UseNotification) => {
    if (!event?.pageY) return;

    const currentTime = Date.now(); // Текущее время
    if (lastClickTime && currentTime - lastClickTime < 1000) {
      // Если прошло меньше 1 секунды с последнего клика
      if (timeoutId) {
        clearTimeout(timeoutId); // Очистка предыдущего таймера
      }
    } else {
      dispatch(onNotification({ position: event.pageY, text: textType }));
    }

    lastClickTime = currentTime; // Обновление времени последнего клика

    timeoutId = setTimeout(() => {
      dispatch(onNotification(null));
    }, 1000); // Установка нового таймера
  };

  return { handleClick };
};
