import { FC } from 'react';
import cn from 'classnames';
import { Icon } from '../Icon/Icon';
import s from './styles.module.scss';

interface MarkerProps {
  classNames?: string;
}

export const Marker: FC<MarkerProps> = ({ classNames }) => {
  return (
    <div className={cn(s.marker, classNames)}>
      <Icon iconName={'redMarker'} />
    </div>
  );
};
