import { useSettings } from 'hooks';

const LogsPage = () => {
  const { errorLogs } = useSettings();
  return (
    <div
      style={{
        padding: '10px',
        background: '#000',
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
      }}
    >
      <button
        style={{
          border: '1px solid #fff',
          color: '#fff',
          margin: '10px 0 0 0',
          width: 'fit-content',
          padding: '0 10px',
        }}
        onClick={() => {
          navigator.clipboard.writeText(errorLogs.join('\n'));
        }}
      >
        Copy logs
      </button>
      {errorLogs?.map((log, i) => {
        return (
          <p style={{ fontSize: '12px', color: '#fff' }} key={i}>
            {log}
          </p>
        );
      })}
    </div>
  );
};
export default LogsPage;
