export enum ModalIdType {
  WELCOME_BACK = 'welcomeBack',
  DELETE_ACC = 'deleteAcc',
  ADD_STAFF = 'addStaff',
  NOTIFICATION = 'notification',
  CONFIRM_MODAL = 'confirmModal',
  HIRE_STAFF = 'hireStaff',
  SELL_FRIEND = 'sellFriend',
  DELETE_FRIEND = 'deleteFriend',
  NEW_FRIEND = 'newFriend',
  REFRESH_ENERGY = 'refreshEnergy',
  NEW_CONNECTION = 'newConnection',
}

export type ModalComponentType = {
  [key in ModalIdType]: React.ReactNode;
};
