import { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Avatar, Icon, ModalIdType, OwnerLine, UserCardsWrapper } from 'components';
import { abbreviateNumber } from 'shared/helpers/abbreviateNumber';
import { Friend, SellFriendInfoWelcomeBack } from 'shared/types';
import { calculateIncomeFormula } from 'shared/helpers/calculateIncomeFormula';
import { useAppDispatch, useGetModalData, useGetOwner, useGetUserData } from 'src/hooks';
import { modalIsOpen, setHireEmployeeModalData } from 'store/slices/modalSlice';
import s from './styles.module.scss';

export interface FriendCardShortProps {
  classNames?: string;
  onClick?: () => void;
  data?: Friend;
  sellData?: SellFriendInfoWelcomeBack;
  isDelete?: boolean;
  variant?: 'income' | 'resell' | 'newFriend';
}

export const FriendCardShort: FC<FriendCardShortProps> = ({
  classNames,
  data,
  onClick,
  variant = 'income',
  sellData,
}) => {
  const { first_name, last_name, username, power, photo_url, owner_id } = data || {};
  const { t } = useTranslation();
  const { newFriendInfo } = useGetModalData();
  const { user_id, money, self_sell_profit } = useGetUserData();
  const dispatch = useAppDispatch();
  const income = calculateIncomeFormula(power);
  const isMoneyEnough = money > newFriendInfo?.price;
  const owner = useGetOwner(owner_id);

  const hireEmployee = () => {
    dispatch(setHireEmployeeModalData(newFriendInfo));
    dispatch(modalIsOpen(ModalIdType.HIRE_STAFF));
  };

  const renderIncome = () => {
    return (
      <>
        <Icon iconName={'coin'} size={[17, 17]} />
        <div style={{ whiteSpace: 'nowrap' }}>
          <span className={s.income}>{abbreviateNumber(income)}</span>
          {t('common.perH')}
        </div>
      </>
    );
  };
  const renderResell = () => {
    return (
      <>
        <span className={s.resell}>+{abbreviateNumber(sellData?.profit || self_sell_profit)}</span>
        <Icon iconName={'coin'} size={[24, 24]} />
      </>
    );
  };

  const hireButton = () => {
    return (
      <div
        className={cn(s.button, {
          [s.blue]: newFriendInfo.owner_id !== user_id,
        })}
        onClick={hireEmployee}
      >
        <div className={s.hireButtonContent}>
          <div>{t('common.hire')}</div>

          <div className={s.priceWrapper}>
            <div className={s.coinIcon}>
              <Icon iconName={'coin'} size={[15, 15]} />
            </div>

            <div className={cn(s.text, isMoneyEnough ? s.textGold : s.textRed)}>
              {abbreviateNumber(newFriendInfo.price)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const hiredButton = () => {
    return (
      <div
        className={cn(s.button, {
          [s.disabled]: newFriendInfo.owner_id === user_id,
        })}
      >
        <div className={s.hiredButton}>{t('common.hired')}</div>
      </div>
    );
  };

  const getAction = () => {
    switch (variant) {
      case 'income':
        return renderIncome();
      case 'resell':
        return renderResell();
      case 'newFriend':
        return <>{newFriendInfo.owner_id === user_id ? hiredButton() : hireButton()}</>;
      default:
        return null;
    }
  };

  return (
    <UserCardsWrapper onClick={onClick}>
      <div className={cn(s.wrapper, classNames)}>
        <div>
          <Avatar variant={'Employees'} image={photo_url} />
        </div>

        <div className={s.content}>
          <div className={s.info}>
            <div className={s.userName}>{`${first_name} ${last_name} ${username ? `(${username})` : ''}`}</div>

            <div className={s.powerWrapper}>
              <Icon iconName={'power'} size={[17, 17]} />
              <div className={s.power}>{abbreviateNumber(power)}</div>
            </div>
          </div>

          <div className={s.incomeWrapper}>{getAction()}</div>
        </div>
      </div>

      <OwnerLine owner={owner} isSmall />
    </UserCardsWrapper>
  );
};
