import { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import s from './styles.module.scss';

interface FiendsListFilterProps {
  isSelected: number;
  onSelected: (value: number) => void;
}

export const FiendsListFilter: FC<FiendsListFilterProps> = ({ isSelected, onSelected }) => {
  const { t } = useTranslation();

  const filterButtons = [
    { id: 1, title: t('inviteFriends.filters.all') },
    { id: 2, title: t('inviteFriends.filters.hired') },
    { id: 3, title: t('inviteFriends.filters.available') },
  ];

  return (
    <div className={s.friendsList}>
      <span className={s.label}>{t('inviteFriends.filters.title')}</span>

      <div className={s.buttons}>
        {filterButtons?.map(({ title, id }) => (
          <button key={id} className={cn(s.button, isSelected === id ? s.active : '')} onClick={() => onSelected(id)}>
            {title}
          </button>
        ))}
      </div>
    </div>
  );
};
