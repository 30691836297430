import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components';
import { useAppDispatch } from 'hooks';
import { PATHS } from 'routes';
import { clearStaffOfficeOwner, modalIsClose } from 'store/slices/modalSlice';
import { clearStaffOffice } from 'store/slices/userSlice';
import s from './styles.module.scss';

export const CloseButton = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handlePush = () => {
    navigate(PATHS.FRIENDS);
    dispatch(clearStaffOffice());
    dispatch(clearStaffOfficeOwner());
    dispatch(modalIsClose());
  };
  return (
    <Button onClick={handlePush} variant={'White'} classNames={s.button}>
      {t('common.close')}
    </Button>
  );
};
