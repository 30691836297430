import { FC } from 'react';
import s from './styles.module.scss';

interface TapCoinProps {
  tapSpeed: number;
  visibility: boolean;
}

export const TapCoin: FC<TapCoinProps> = ({ tapSpeed, visibility }) => {
  return (
    <div className={s.tapCoin} style={{ visibility: visibility ? 'visible' : 'hidden' }}>
      {tapSpeed ? `+${tapSpeed}` : tapSpeed}
    </div>
  );
};
