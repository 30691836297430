import { FC } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { inviteLink, placeOrder } from 'consts';
import { useBackground, useGetUserData, useSettings, useTutor } from 'hooks';
import { Slot } from 'src/components';
import s from './styles.module.scss';

export interface OfficePlacesProps {
  drawTables: any;
  slotsLength: number;
}

export const OfficePlaces: FC<OfficePlacesProps> = ({ drawTables, slotsLength }) => {
  const { viewportHeight } = useBackground();
  const { isTutor } = useSettings();
  const { step } = useTutor();
  const { user_id } = useGetUserData()

  const officeWidth = () => {
    if (viewportHeight > 670) return { maxWidth: '100%' };
    if (viewportHeight < 670) return { maxWidth: '80%' };
    if (viewportHeight < 600) return { maxWidth: '70%' };
  };

  return (
    <div className={s.officeWrapper} style={{ ...officeWidth(), zIndex: isTutor && step === 2 ? '11' : '1' }}>
      <div className={cn(s.workPlace, !isTutor || step < 2 ? s.hidden : '', s[`slots_1`])}>
        <Link to={inviteLink(user_id).link}>
          <Slot
            classNames={s.tutor}
            end_time={0}
            friend_id={0}
            friend_owner_id={0}
            revenue={0}
            slot={null}
            start_time={0}
          />
        </Link>
      </div>
      {placeOrder?.[slotsLength]?.map((item, index) => (
        <div key={index} className={cn(s.workPlace, s[`slots_${slotsLength}`], isTutor ? s.hidden : '')}>
          {drawTables(item[0], item[1])}
        </div>
      ))}
    </div>
  );
};
