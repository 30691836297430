import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Buttons } from 'shared/types';

interface InitialState {
  buttonState: Buttons | null;
  isButtonDisabled: boolean;
}

const initialState: InitialState = {
  buttonState: null,
  isButtonDisabled: false,
};

const officeSlice = createSlice({
  name: 'officeSlice',
  initialState,
  reducers: {
    changeButtonState: (state, action: PayloadAction<Buttons>) => {
      state.buttonState = action.payload;
    },
    changeButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.isButtonDisabled = action.payload;
    },
  },
});

export const { changeButtonState, changeButtonDisabled } = officeSlice.actions;

export default officeSlice.reducer;
