// Локали
export enum LanguagesEnum {
  EN = 'en',
  RU = 'ru',
  FR = 'fr',
  DE = 'de',
  ES = 'es',
  TH = 'th',
  UZ = 'uz',
  KZ = 'kz',
}

// Енам типов кнопок
export enum Buttons {
  START = 'start',
  CLEM = 'clem',
  WORKING = 'working',
}

export enum SettingTabs {
  SETTINGS_MAIN = 'main',
  CHANGE_LANGUAGE = 'language',
}

export enum INotificationType {
  NO_ENOUGH_MONEY = 'NO_ENOUGH_MONEY',
  WORK_NOT_COMPLETED = 'WORK_NOT_COMPLETED',
  TAKE_YOU_INCOME = 'TAKE_YOU_INCOME',
}

export interface INotification {
  position: number;
  text: INotificationType;
}
export interface IUpdateCard {
  id?: number | string;
  title: string;
  description: string;
  type: SkillEnum;
}

type ISkillAttributes = {
  big_boss: number;
  clickerman: number;
  freshman: number;
  hardy: number;
  hr_guru: number;
  motivator: number;
  team_leader: number;
  trader: number;
};

export type IInAppPurchase = {
  title: string;
  description: string;
  price: number;
  money: number;
  id: number;
};

/**
 * Интерфейс, представляющий структуру данных пользователя.
 * @property cmd - Команда, связанная с данными пользователя.
 * @property user_id - Уникальный идентификатор пользователя.
 * @property owner_id - Уникальный идентификатор владельца пользователя.
 * @property first_name - Имя пользователя.
 * @property last_name - Фамилия пользователя.
 * @property username - Логин пользователя.
 * @property photo_url - URL на фото профиля пользователя.
 * @property money - Текущая сумма денег у пользователя.
 * @property skill_levels - Уровни навыков пользователя.
 * @property upgrade_costs - Стоимость улучшения навыков пользователя.
 * @property skill_values - Текущие значения навыков пользователя.
 * @property skill_values_add - Дополнительные значения навыков пользователя.
 * @property power - Уровень силы пользователя.
 * @property energy - Текущий уровень энергии пользователя.
 * @property max_energy - Максимальный уровень энергии пользователя.
 * @property recovery_rate - Скорость восстановления энергии.
 * @property revenue_rate - Скорость получения дохода.
 * @property friends - Список друзей пользователя.
 * @property more_friends - Есть ли возможность добавить больше друзей.
 * @property slots - Список слотов, используемых пользователем.
 * @property in_apps - Список внутриигровых покупок пользователя.
 * @property server_time - Время на сервере.
 * @property missed_owners - Пропущенные владельцы (если есть).
 * @property revenue_formula_m - Параметр m для формулы дохода.
 * @property revenue_formula_n - Параметр n для формулы дохода.
 * @property price - Цена пользователя.
 * @property offline_revenue - Доход пользователя в оффлайне.
 * @property self_sell_profit - Прибыль от продажи самого себя.
 * @property sell_friend_info - Информация о продаже друзей (для возвращения).
 * @property free_energy_recovery_time - Время до восстановления бесплатной энергии.
 */
export interface IUserData {
  cmd: string;
  user_id: number;
  owner_id: number;
  first_name: string;
  last_name: string;
  username: string;
  photo_url: string;
  money: number;
  skill_levels: ISkillAttributes;
  upgrade_costs: ISkillAttributes;
  skill_values: ISkillAttributes;
  skill_values_add: ISkillAttributes;
  power: number;
  energy: number;
  max_energy: number;
  recovery_rate: number;
  revenue_rate: number;
  friends: Friend[];
  more_friends: boolean;
  slots: SlotType[];
  in_apps: IInAppPurchase[];
  server_time: number;
  missed_owners?: Friend[] | null;
  revenue_formula_m: number;
  revenue_formula_n: number;
  price: number;
  offline_revenue: number;
  self_sell_profit: number;
  sell_friend_info: SellFriendInfoWelcomeBack[];
  free_energy_recovery_time: number;
  price_modifier: number;
  friends_friends: Friend[];
  new_players: Friend[];
  top_players: Friend[];
  referral_ids: number[];
}

export type SkillEnum =
  | 'clickerman'
  | 'hardy'
  | 'freshman'
  | 'big_boss'
  | 'hr_guru'
  | 'motivator'
  | 'team_leader'
  | 'trader';

export interface Friend {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  photo_url: string;
  price: number;
  power: number;
  owner_id: number;
  referral_id?: number;
  friends_count?: number;
  friends_hired?: number;
  office_level?: number;
  owners_before?: number;
  start_date?: number;
  added?: number;
}

export interface GetFriendsResponse {
  friends: Friend[];
  more_friends?: boolean;
}

export interface BuyFriendResponse {
  cmd: string;
  friend_id: number;
  new_price: number;
  money: number;
  price_changed: boolean;
  error?: string;
  owners_before?: number;
}

export interface SlotType {
  slotId?: number;
  friend_id: number;
  friend_owner_id: number;
  start_time: number;
  end_time: number;
  revenue: number;
  time_left?: number;
}

export type SelFriendInfo = {
  friend_id: number;
  owner_id: number;
  new_price: number;
  slot_revenue: number;
  money: number;
  added: number;
};

export type NewFriendInfo = Friend & {
  money: number;
  added: number;
};

// Типы для PayloadAction
export type UpdateAfterClickPayload = {
  money: number;
  energy: number;
};

export type UpdateAfterUpgradePayload = {
  skill: SkillEnum;
  money: number;
  level: number;
  value: number;
  value_add: number;
  next_upgrade_cost: number;
  power: number;
  energy: number;
};

export type UpdateAfterManageSlotPayload = {
  slot_id: number;
  friend_id: number;
};

export type UpdateAfterStartWorkPayload = {
  slots: SlotType[];
};

export type UpdateAfterGetWorkingRevenuePayload = {
  money: number;
  slots: SlotType[];
};

export type UpdateAfterGetFriendInfoPayload = {
  friends: Friend[];
  slots: SlotType[];
};

export type UpdateAfterPurchasePayload = {
  money: number;
};

export type UpdateAfterSellFriendPayload = {
  friend_id: number;
  owner_id: number;
  new_price: number;
  slot_revenue: number;
  money: number;
  added: number;
  owner_first_name?: string;
  owner_last_name?: string;
  owner_username?: string;
  owner_photo_url?: string;
  owners_before?: number;
};

export type SellFriendInfoWelcomeBack = {
  buyer_id: number;
  friend_id: number;
  profit: number;
  time: number;
};
