import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FriendCardLockPage, InviteFriend } from 'feature';
import { useGetUserData } from 'src/hooks';
// import { isProd } from 'consts';
import s from './styles.module.scss';

export interface LockPageProps {}

const LockPage: FC<LockPageProps> = () => {
  const { user_id, invitedFriends } = useGetUserData();
  const { t } = useTranslation();

  return (
    <div className={s.wrapper}>
      <div className={s.contentWrapper}>
        <p className={s.titleTop}>{t('lockPage.topTitle')}</p>
        <div className={s.cards}>
          <FriendCardLockPage user_id={user_id} classNames={s.first} friend={invitedFriends?.[0]} />
        </div>
        <p className={s.titleBottom}>{t('lockPage.bottomText')}</p>

        <InviteFriend isContinueButton={invitedFriends?.length >= 1} />
      </div>
    </div>
  );
};

export default LockPage;
