import React, { FC, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components';
import s from './styles.module.scss';

interface InviteFriendsProps {
  link: string;
  isContinueButton?: boolean;
}

export const InviteFriends: FC<InviteFriendsProps> = ({ link, isContinueButton }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const { t } = useTranslation();

  const handlerCopy = useCallback((textToCopy: string) => {
    navigator.clipboard.writeText(textToCopy);
    setTooltipVisible(true);
    setTimeout(() => setTooltipVisible(false), 1500); // Скрываем тултип через 1.5 секунды
  }, []);

  return (
    <div className={s.inviteFriends} style={{ opacity: isContinueButton ? 0 : 1 }}>
      <div className={s.labelWrapper}>
        <h3 className={s.label}>{t('inviteFriends.invite')}</h3>

        <div className={s.subLabel}>
          <p>+ 1000</p>
          <Icon iconName={'coin'} classNames={s.coinIcon} />
          <p>{t('inviteFriends.inviteBonus')}</p>
        </div>

        <div className={s.linkBlock}></div>
      </div>

      <div className={s.linkWrapper}>
        <p className={s.linkText}>{link}</p>

        <div className={tooltipVisible ? s.showTooltip : ''}>
          <button onClick={() => handlerCopy(link)}>
            <Icon iconName={'copy'} classNames={s.copyIcon} />
          </button>

          <div className={s.tooltip}>{t('notification.copied')}</div>
        </div>
      </div>
    </div>
  );
};
