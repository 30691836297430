import { useState, useEffect } from 'react';
import { formatTime } from 'shared/helpers/formatTime';
import { useGetUserData } from './useGetUserData';

export const useTimeLeft = (end_time: number, text: string | null) => {
  const [timeLeft, setTimeLeft] = useState<string>('');
  const { timeDiff } = useGetUserData();

  const calculateTimeLeft = () => {
    const remainingTime = end_time + timeDiff - Date.now() / 1000;
    setTimeLeft(remainingTime > 0 ? formatTime(remainingTime) : text);
  };

  useEffect(() => {
    calculateTimeLeft();
    const interval = setInterval(calculateTimeLeft, 100);

    return () => clearInterval(interval);
  }, [end_time, timeDiff]);

  return timeLeft;
};
