import React, { FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button, EnergyLimit, Icon } from 'components';
import { useGetModalData, useGetUserData, useTimeLeft } from 'hooks';
import { sendFreeEnergy } from 'store/service/methods';
import refrigerator from 'assets/images/refrigerator.webp';
import s from './styles.module.scss';

export interface RefreshEnergyModalProps {
  classNames?: string;
}

export const RefreshEnergyModal: FC<RefreshEnergyModalProps> = ({ classNames }) => {
  const { energy, max_energy, isFreeEnergyBoostActive, free_energy_recovery_time } = useGetUserData();
  const timeLeft = useTimeLeft(free_energy_recovery_time, null);
  const { modalId } = useGetModalData();
  const { t } = useTranslation();

  const handleRefresh = () => {
    sendFreeEnergy();
  };

  const refreshButton = () => {
    return (
      <Button onClick={handleRefresh} variant={'Green'}>
        <div className={s.buttonText}>{t('refreshEnergy.title')}</div>
        <Icon iconName={'lightning'} size={[25, 25]} />
      </Button>
    );
  };

  const timerButton = () => {
    return (
      <Button onClick={handleRefresh} variant={'Blue'} classNames={s.timerButton}>
        <Icon iconName={'clock'} size={[25, 25]} />
        <div className={s.buttonText}>{timeLeft}</div>
      </Button>
    );
  };

  return (
    <div className={cn(s.wrapper, classNames)}>
      <div className={s.contentWrapper}>
        <div className={cn(s.refrigeratorImage, { [s.refrigeratorImageAnimation]: modalId })}>
          <img src={refrigerator} alt="refrigerator image" />
        </div>

        <div className={s.content}>
          <div className={s.contentTitle}>{t('refreshEnergy.title')}</div>
          <div className={s.energy}>
            <EnergyLimit energy={energy} energyLimit={max_energy} />
          </div>
        </div>
      </div>

      <div className={s.buttonWrapper}>{isFreeEnergyBoostActive && !timeLeft ? refreshButton() : timerButton()}</div>
    </div>
  );
};
