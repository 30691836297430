import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  BuyFriendResponse,
  Friend,
  GetFriendsResponse,
  IUserData,
  SelFriendInfo,
  SlotType,
  UpdateAfterGetFriendInfoPayload,
  UpdateAfterGetWorkingRevenuePayload,
  UpdateAfterManageSlotPayload,
  UpdateAfterPurchasePayload,
  UpdateAfterSellFriendPayload,
  UpdateAfterStartWorkPayload,
  UpdateAfterUpgradePayload,
} from 'shared/types';
// import store from '..';
// import { setHireEmployeeModalData } from './modalSlice';

interface InitialState {
  user: IUserData | null;
  userFriendFriends: Friend[] | null;
  userFriendSlots: SlotType[] | null;
  timeDiff: number;
  sellFriendInfo: SelFriendInfo | null;
  deleteFriendInfo: SlotType | null;
}

const initialState: InitialState = {
  user: null,
  userFriendFriends: null,
  userFriendSlots: null,
  timeDiff: 0,
  sellFriendInfo: null,
  deleteFriendInfo: null,
};

const findFriendById = (friends, friend_id) => (friends || [])?.find((friend) => friend.id === friend_id);

const updateFriendData = (friend, owner_id, new_price, owners_before) => {
  if (friend) {
    friend.owner_id = owner_id;
    friend.price = new_price;
    friend.owners_before = owners_before;
  }
};

const updateSlotAfterSell = (slot, timeDiff, slot_revenue, owner_id) => {
  if (slot) {
    slot.end_time = Math.floor(Date.now() / 1000 + timeDiff); // Устанавливаем новое время окончания работы
    slot.time_left = 0; // Обнуляем оставшееся время работы
    slot.revenue = slot_revenue; // Обновляем доход от работы в слоте
    slot.friend_owner_id = owner_id; // Обновляем ID владельца друга в слоте
  }
};

const updateSkillValues = (state, skill, payload) => {
  state.user.skill_levels[skill] = payload.level;
  state.user.skill_values[skill] = payload.value;
  state.user.skill_values_add[skill] = payload.value_add;
  state.user.upgrade_costs[skill] = payload.next_upgrade_cost;
  state.user.power = payload.power;
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    // Установить данные пользователя
    setUserData: (state, action: PayloadAction<IUserData>) => {
      state.user = action.payload;
      state.timeDiff = Math.floor(Date.now() / 1000) - action.payload.server_time;
    },
    // Установить друзей пользователя
    setFriends: (state, action: PayloadAction<GetFriendsResponse>) => {
      state.user.friends = action.payload.friends;
    },
    // Обновить состояние после клика (например, обновление денег и энергии)
    updateAfterClick: () => {
      // state.user.energy = action.payload.energy;
      // state.user.money = action.payload.money;
    },
    // Обновить состояние после клика (например, обновление денег и энергии)
    updateAfterDebouncedClick: (state) => {
      state.user.energy -= state.user.skill_values?.['clickerman'];
      state.user.money += state.user.skill_values?.['clickerman'];
    },
    // Обновить состояние после покупки друга
    updateAfterBuyFriend: (state, action: PayloadAction<BuyFriendResponse>) => {
      const { friend_id, new_price, money, owners_before } = action.payload;

      // Обновляем деньги пользователя
      state.user.money = money;
      // Обновляем друга в слоте, если он там находится
      const friendInSlot = state.user.slots.find((slot) => slot.friend_id === friend_id);
      if (friendInSlot) {
        friendInSlot.friend_owner_id = state.user.user_id;
      }

      // Функция для обновления данных друга
      const updateFriendInList = (list: Friend[], friendId: number) => {
        const friend = findFriendById(list, friendId);
        if (friend) {
          updateFriendData(friend, state.user.user_id, new_price, owners_before);
        }
      };

      // Обновляем друга во всех списках
      updateFriendInList(state.user.friends, friend_id);
      updateFriendInList(state.userFriendFriends, friend_id);
      updateFriendInList(state.user.top_players, friend_id);
      updateFriendInList(state.user.new_players, friend_id);

      // Если купленного друга нет в списке друзей, добавляем его
      const boughtFriend = findFriendById(state.user.friends, friend_id);
      if (!boughtFriend) {
        const allPotentialFriends = [
          ...(state.user.friends_friends || []),
          ...(state.user.missed_owners || []),
          ...(state.user.new_players || []),
          ...(state.user.top_players || []),
          ...(state.userFriendFriends || []),
        ];
        const newFriend = findFriendById(allPotentialFriends, friend_id);
        if (newFriend) {
          state.user.friends = [
            ...(state.user.friends || []),
            { ...newFriend, owner_id: state.user.user_id, price: new_price, owners_before },
          ];
        }
      }
      state.user.friends_friends = state?.user?.friends_friends?.filter((_) => _.id != friend_id);
    },
    // Обновить состояние после повышения навыка
    updateAfterUpgrade: (state, action: PayloadAction<UpdateAfterUpgradePayload>) => {
      const { skill, money, value, energy } = action.payload;
      state.user.money = money;

      updateSkillValues(state, skill, action.payload);

      if (skill === 'hardy') {
        state.user.max_energy = value;
        state.user.energy = energy;
      } else if (skill === 'freshman') {
        state.user.recovery_rate = value / 3600;
      } else if (skill === 'big_boss') {
        state.user.revenue_rate = value / 3600;
      } else if (skill === 'hr_guru') {
        state.user.slots.push({
          end_time: 0,
          friend_id: 0,
          friend_owner_id: 0,
          revenue: 0,
          start_time: 0,
          time_left: 0,
        });
      } else if (skill === 'team_leader') {
        const teamLeaderValue = value;
        state.user.slots = state.user.slots?.map((slot) => {
          const friend = findFriendById(state.user.friends, slot.friend_id);
          if (friend && slot.end_time) {
            slot.revenue =
              (((state.user.revenue_formula_n + friend.power / state.user.revenue_formula_m) *
                (1 + teamLeaderValue / 100)) /
                60) *
              state.user.skill_values['motivator'];
          }
          return slot;
        });
      }
    },
    // Обновить состояние после управления слотом
    updateAfterManageSlot: (state, action: PayloadAction<UpdateAfterManageSlotPayload>) => {
      const { slot_id, friend_id } = action.payload;
      const slot = state.user.slots[slot_id];

      if (slot) {
        slot.friend_id = friend_id;
        if (!friend_id) {
          Object.assign(slot, {
            start_time: 0,
            end_time: 0,
            time_left: 0,
            friend_owner_id: 0,
            revenue: 0,
          });
        }
      } else {
        console.error(`Слот с ID ${slot_id} не найден`);
      }
    },
    // Обновить состояние после начала работы
    updateAfterStartWork: (state, action: PayloadAction<UpdateAfterStartWorkPayload>) => {
      state.user.slots = action.payload.slots;
      if (state.deleteFriendInfo) {
        state.deleteFriendInfo = action.payload.slots.find((_) => _.friend_id === state.deleteFriendInfo.friend_id);
      }
    },
    // Обновить состояние после получения дохода с работы
    updateAfterGetWorkingRevenue: (state, action: PayloadAction<UpdateAfterGetWorkingRevenuePayload>) => {
      state.user.money = action.payload.money;
      state.user.slots = action.payload.slots;
      if (state.deleteFriendInfo) {
        state.deleteFriendInfo = action.payload.slots.find((_) => _.friend_id === state.deleteFriendInfo.friend_id);
      }
    },
    // Обновить информацию о друзьях пользователя
    updateAfterGetFriendInfo: (state, action: PayloadAction<UpdateAfterGetFriendInfoPayload>) => {
      state.userFriendFriends = action.payload.friends;
      state.userFriendSlots = action.payload.slots;
    },
    // Очистить офис от сотрудников
    clearStaffOffice: (state) => {
      state.userFriendFriends = null;
    },
    // Обновить состояние после покупки
    updateAfterPurchase: (state, action: PayloadAction<UpdateAfterPurchasePayload>) => {
      state.user.money = action.payload.money;
    },
    // Обновить состояние после продажи друга
    updateAfterSellFriend: (state, action: PayloadAction<UpdateAfterSellFriendPayload>) => {
      const {
        friend_id,
        owner_id,
        new_price,
        slot_revenue,
        money,
        owner_first_name,
        owner_last_name,
        owner_username,
        owner_photo_url,
        owners_before,
      } = action.payload;

      // Если владелец друга отсутствует в текущем списке друзей, добавляем его в missed_friends
      if (!state.user.friends?.some((friend) => friend.id === owner_id)) {
        const missedFriend = findFriendById(state.user.missed_owners, owner_id);
        if (!missedFriend) {
          const newOwner = {
            id: owner_id,
            first_name: owner_first_name || '',
            last_name: owner_last_name || '',
            username: owner_username || '',
            photo_url: owner_photo_url || '',
            owner_id: 0,
            power: 0,
            price: 0,
          };

          state.user.missed_owners = state.user.missed_owners ? [...state.user.missed_owners, newOwner] : [newOwner];
        }
      }
      state.sellFriendInfo = action.payload; // Сохраняем информацию о продаже друга
      state.user.money = money; // Обновляем баланс пользователя

      const friend = findFriendById(state.user.friends, friend_id); // Находим друга по ID
      updateFriendData(friend, owner_id, new_price, owners_before);

      const friendSlot = state.user.slots.find((slot) => slot.friend_id === friend_id); // Находим слот, в котором был друг
      updateSlotAfterSell(friendSlot, state.timeDiff, slot_revenue, owner_id);

      const updateFriendInList = (list: Friend[], friendId: number) => {
        const friend = findFriendById(list, friendId);
        if (friend) {
          updateFriendData(friend, owner_id, new_price, owners_before);
        }
      };

      // Обновляем друга во всех списках
      updateFriendInList(state.user.friends, friend_id);
      updateFriendInList(state.userFriendFriends, friend_id);
      updateFriendInList(state.user.top_players, friend_id);
      updateFriendInList(state.user.new_players, friend_id);
    },
    // Увеличить энергию и деньги пользователя
    increaseEnergyAndMoney: (state) => {
      if (state.user && state.user.max_energy !== undefined) {
        state.user.energy = Math.min(state.user.energy + state.user.recovery_rate, state.user.max_energy);
        state.user.money = state.user.money + state.user.revenue_rate;
      }
    },
    // Установить данные для удаления друга
    setDeleteFriendData: (state, action: PayloadAction<SlotType>) => {
      state.deleteFriendInfo = action.payload;
    },
    // Очистка данных в оффлайне
    clearWelcomeBackInfo: (state) => {
      state.user.sell_friend_info = null;
      state.user.offline_revenue = 0;
      state.user.self_sell_profit = 0;
    },
    addNewFriend: (state, action: PayloadAction<Friend & { money: number }>) => {
      state.user.friends = [...(state?.user?.friends ?? []), action.payload];
      state.user.money = action.payload.money;
      state.user.referral_ids = [...(state?.user?.referral_ids ?? []), action.payload.id];
    },
    freeEnergyBoost: (
      state,
      action: PayloadAction<{
        energy: number;
        free_energy_recovery_time: number;
      }>
    ) => {
      state.user.energy = action.payload.energy;
      state.user.free_energy_recovery_time = action.payload.free_energy_recovery_time;
    },
    handleNewRef: (state, action: PayloadAction<{ referral_id: number }>) => {
      state.user.referral_ids = [...(state?.user?.referral_ids ?? []), action.payload.referral_id];
    },
  },
});

export const {
  increaseEnergyAndMoney,
  setUserData,
  setFriends,
  updateAfterClick,
  updateAfterUpgrade,
  updateAfterBuyFriend,
  updateAfterManageSlot,
  updateAfterStartWork,
  updateAfterGetWorkingRevenue,
  updateAfterGetFriendInfo,
  updateAfterPurchase,
  updateAfterSellFriend,
  setDeleteFriendData,
  clearStaffOffice,
  clearWelcomeBackInfo,
  addNewFriend,
  freeEnergyBoost,
  updateAfterDebouncedClick,
  handleNewRef,
} = userSlice.actions;
export default userSlice.reducer;
