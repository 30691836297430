import { FC } from 'react';
import cn from 'classnames';
import {
  AddStaff,
  ConfirmModal,
  DeleteAccModal,
  DeleteFriendModal,
  HireEmployee,
  NewFriendModal,
  Notification,
  RefreshEnergyModal,
  SellFriendModal,
  WelcomeBackModal,
  NewConnectionModal,
  HelpModal,
} from 'feature';
import { useAppDispatch, useGetModalData, useGetUserData } from 'hooks';
import { helpIsClose, modalIsClose } from 'store/slices/modalSlice';
import { clearWelcomeBackInfo } from 'store/slices/userSlice';
import s from './styles.module.scss';
import { ModalComponentType, ModalIdType } from './types';

export const ModalWrapper: FC = () => {
  const dispatch = useAppDispatch();
  const { modalId, hireEmployeeModalData, slotData, addStaffData, helpId } = useGetModalData();
  const { friends } = useGetUserData();

  const handleCloseModal = () => {
    if (helpId) {
      dispatch(helpIsClose());
      return;
    }
    if (modalId === ModalIdType.WELCOME_BACK) {
      dispatch(clearWelcomeBackInfo());
    }
    if (modalId === ModalIdType.NEW_CONNECTION || modalId === ModalIdType.NEW_FRIEND) {
      return;
    }
    dispatch(modalIsClose());
  };

  const modalComponents: ModalComponentType = {
    [ModalIdType.WELCOME_BACK]: <WelcomeBackModal />,
    [ModalIdType.DELETE_ACC]: <DeleteAccModal />,
    [ModalIdType.ADD_STAFF]: <AddStaff data={friends} slot_id={addStaffData?.slot_id} />,
    [ModalIdType.NOTIFICATION]: <Notification />,
    [ModalIdType.CONFIRM_MODAL]: <ConfirmModal data={slotData} />,
    [ModalIdType.HIRE_STAFF]: <HireEmployee data={hireEmployeeModalData} />,
    [ModalIdType.SELL_FRIEND]: <SellFriendModal />,
    [ModalIdType.DELETE_FRIEND]: <DeleteFriendModal />,
    [ModalIdType.NEW_FRIEND]: <NewFriendModal />,
    [ModalIdType.REFRESH_ENERGY]: <RefreshEnergyModal />,
    [ModalIdType.NEW_CONNECTION]: <NewConnectionModal />,
  };

  return (
    <>
      <div className={cn(s.modalOverflow, modalId ? s.modalIsOpened : s.modalOnClose)} onClick={handleCloseModal}>
        {/* Модалка */}
        <div
          className={cn(s.modal, modalId ? s.contentVisible : s.contentHidden)}
          onClick={(event) => event.stopPropagation()}
        >
          {modalId ? modalComponents[modalId] : null}
        </div>
      </div>
      <div className={cn(s.modalOverflow, helpId ? s.modalIsOpened : s.modalOnClose)} onClick={handleCloseModal}>
        {/* Подсказка */}
        <div
          className={cn(s.modal, helpId ? s.contentVisible : s.contentHidden)}
          onClick={(event) => event.stopPropagation()}
        >
          <HelpModal />
        </div>
      </div>
    </>
  );
};
