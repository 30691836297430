import { FC, MouseEvent } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Avatar, Icon, OwnerLine, UserCardsWrapper } from 'components';
import { abbreviateNumber } from 'shared/helpers/abbreviateNumber';
import { Friend } from 'shared/types';
import { useGetOwner, useGetUserData } from 'src/hooks';
import { openHelpModal } from 'src/feature/Modals/HelpModal/HelpModal';
import { calculateIncomeFormula, calculateResellFormula } from 'shared/helpers/calculateIncomeFormula';
import { formatTimestamp } from 'shared/helpers/formatDate';
import s from './styles.module.scss';

export interface FriendCardDetailsProps {
  classNames?: string;
  friend: Friend;
}

export const FriendCardDetails: FC<FriendCardDetailsProps> = ({ classNames, friend }) => {
  const { t } = useTranslation();
  const { user_id } = useGetUserData();
  const {
    first_name,
    last_name,
    power,
    username,
    photo_url,
    owner_id,
    office_level,
    friends_count,
    owners_before,
    friends_hired,
    price,
    start_date,
  } = friend || {};
  const owner = useGetOwner(friend?.owner_id);

  const isHire = owner_id === user_id;
  const income = calculateIncomeFormula(friend.power);

  // Открыть подсказку
  const handleOpenInfo = (e: MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    openHelpModal('resell');
  };

  const userInfo = [
    { id: 1, title: t('common.startDate'), value: formatTimestamp(start_date) },
    { id: 2, title: t('common.officeLevel'), value: office_level },
    { id: 3, title: t('common.friends'), value: friends_count },
    { id: 4, title: t('common.ownerBefore'), value: owners_before },
    { id: 5, title: t('common.friendsHire'), value: friends_hired },
    {
      id: 6,
      title: t('common.resell'),
      value: (
        <>
          <Icon iconName={'coin'} size={[17, 17]} />
          <div>
            <span className={s.income}>{calculateResellFormula(price)}</span>
          </div>
          <Icon iconName={'info'} size={[15, 15]} onClick={handleOpenInfo} />
        </>
      ),
    },
  ];

  return (
    <UserCardsWrapper>
      <div className={cn(s.wrapper, classNames)}>
        {isHire && <div className={s.hired}>{t('common.hired')}</div>}
        <OwnerLine owner={owner} />

        <div className={s.userNameWrapper}>
          <div>
            <Avatar image={photo_url} variant={'Square'} />
          </div>

          <div className={s.userName}>{`${first_name} ${last_name} ${username ? `(${username})` : ''}`}</div>
        </div>

        <div className={s.userSkills}>
          <div className={s.skill}>
            <div className={s.skillTitle}>{t('common.power')}:</div>
            <Icon iconName={'power'} size={[17, 17]} />
            <div className={s.power}>{abbreviateNumber(power)}</div>
          </div>

          <div className={s.skill}>
            <div className={s.skillTitle}>{t('common.income')}:</div>
            <Icon iconName={'coin'} size={[17, 17]} />
            <div style={{ whiteSpace: 'nowrap' }}>
              <span className={s.income}>{abbreviateNumber(income)}</span>
              {t('common.perH')}
            </div>
          </div>
        </div>

        <div className={s.infoContent}>
          {userInfo?.map(({ title, value, id }) => (
            <div key={id} className={s.infoCell}>
              <div className={s.title}>
                {title}: <span className={s.value}>{value}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </UserCardsWrapper>
  );
};
