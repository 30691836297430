import React, { CSSProperties, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { useSideEffects, useAuthorization, useUpdateOffice } from 'hooks';
import { COMPONENTS, routes } from 'routes';
import { Tutor } from 'components';
import { Layout } from 'src/pages';

// Ленивая загрузка страниц
const OfficePage = React.lazy(() => import('src/pages/OfficePage/OfficePage'));
const BossPage = React.lazy(() => import('src/pages/BossPage/BossPage'));
const FriendsPage = React.lazy(() => import('src/pages/FriendsPage/FrendsPage'));
const ShopPage = React.lazy(() => import('src/pages/ShopPage/ShopPage'));
const SettingsPage = React.lazy(() => import('src/pages/SettingsPage/SettingsPage'));
const StaffOfficePage = React.lazy(() => import('src/pages/StaffOfficePage/StaffOfficePage'));
const NotFoundPage = React.lazy(() => import('src/pages/NotFoundPage/NotFoundPage'));
const LockPage = React.lazy(() => import('src/pages/LockPage/LockPage'));
const LogsPage = React.lazy(() => import('src/pages/LogsPage/LogsPage'));

const pages: { [key in COMPONENTS]: JSX.Element } = {
  [COMPONENTS.OFFICE]: <OfficePage />,
  [COMPONENTS.BOSS]: <BossPage />,
  [COMPONENTS.FRIENDS]: <FriendsPage />,
  [COMPONENTS.SHOP]: <ShopPage />,
  [COMPONENTS.SETTINGS]: <SettingsPage />,
  [COMPONENTS.STAFF_OFFICE]: <StaffOfficePage />,
  [COMPONENTS.ERROR]: <NotFoundPage />,
  [COMPONENTS.LOCK]: <LockPage />,
  [COMPONENTS.LOGS]: <LogsPage />,
};

function App() {
  // Хук сайд эффектов приложения
  useSideEffects();
  // Хук обновления данных офиса
  useUpdateOffice();
  // Хук авторизации пользователя
  useAuthorization();

  const { pathname } = useLocation();

  const styles = (path: string): CSSProperties =>
    pathname === path
      ? {
          visibility: 'visible',
          width: '100%',
          height: '100%',
          overflow: 'visible',
        }
      : {
          visibility: 'hidden',
          width: '0',
          height: '0',
          overflow: 'hidden',
        };

  return (
    <Layout>
      <Suspense fallback={<div>Загрузка...</div>}>
        {routes.map(({ path, componentName }) => (
          <div key={path} style={styles(path)}>
            {pages[componentName]}
          </div>
        ))}
      </Suspense>
      <Tutor />
    </Layout>
  );
}

export default App;
