import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useSettings } from 'hooks';
import { PATHS } from 'routes';
import { changeNextStepTutor } from 'store/slices/settingsSlice';

interface ISteps {
  page: PATHS;
  text: string;
}

export const useTutor = () => {
  const dispatch = useAppDispatch();
  const { tutorStep, isTutor } = useSettings();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const steps: ISteps[] = [
    { page: PATHS.OFFICE, text: t('tutor.step_1') },
    { page: PATHS.BOSS, text: t('tutor.step_2') },
    { page: PATHS.OFFICE, text: t('tutor.step_3') },
  ];

  const onNextStep = (step: number | null) => {
    dispatch(changeNextStepTutor(step));

    navigate(steps[step].page);
  };

  return {
    tutorText: steps[tutorStep]?.text,
    step: tutorStep,
    onNextStep,
    isTutor: isTutor,
  };
};
