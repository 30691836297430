import React, { FC, PointerEvent, useCallback } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Icon, UpdateCardsWrapper } from 'components';
import { useGetUserData, useNoEnoughMoney, useTutor } from 'hooks';
import { abbreviateNumber } from 'shared/helpers/abbreviateNumber';
import { INotificationType, IUpdateCard } from 'shared/types';
import { buySkill } from 'store/service/methods';
import s from './styles.module.scss';

interface UpdateCardProps extends IUpdateCard {
  time?: string;
  upgradeCost?: number;
  skillLevel: number;
  skillValue?: number;
  valueAdd?: number;
}

export const UpdateCard: FC<UpdateCardProps> = ({
  title,
  description,
  time,
  upgradeCost,
  skillLevel,
  skillValue,
  valueAdd,
  type,
}) => {
  const { money } = useGetUserData();
  const isUpdating = money >= upgradeCost && Boolean(valueAdd);
  const { isTutor, onNextStep, step } = useTutor();
  const { t } = useTranslation();

  const isTimeView = () => {
    return (
      <>
        <div className={s.iconWrapper}>
          <Icon iconName={'clock'} />
        </div>
        <div className={s.text}>{time}</div>
      </>
    );
  };
  const isPriceView = () => {
    return (
      <>
        <div className={s.iconWrapper}>
          <Icon iconName={'coin'} />
        </div>
        <div className={cn(s.text, isUpdating ? s.textGold : s.textRed)}>{abbreviateNumber(upgradeCost)}</div>
      </>
    );
  };

  const mainContent = useCallback(() => {
    switch (type) {
      case 'hr_guru':
        return {
          icon: <Icon iconName={'hrGuruMan'} />,
        };
      case 'motivator':
        return {
          icon: <Icon iconName={'clock'} />,
          symbol: 'm',
        };
      case 'team_leader':
      case 'trader':
        return {
          symbol: '%',
        };
      case 'hardy':
      case 'freshman':
        return {
          icon: <Icon iconName={'lightning'} />,
        };
      default:
        return {
          icon: <Icon iconName={'coin'} />,
        };
    }
  }, []);

  const { handleClick } = useNoEnoughMoney();

  const handleUpdate = (event: PointerEvent<HTMLButtonElement>) => {
    if (money < upgradeCost) {
      handleClick({ event, textType: INotificationType.NO_ENOUGH_MONEY });
    }

    if (money > upgradeCost && valueAdd) {
      buySkill(type);
    }
    if (isTutor) {
      onNextStep(2);
    }
  };

  const tutor = isTutor && type === 'hr_guru' && step === 1;

  return (
    <div className={cn(s.upgradeWrapper, tutor ? s.tutor : '')}>
      <UpdateCardsWrapper
        noEnoughMoney={valueAdd ? isUpdating : true}
        onClick={handleUpdate}
        price={upgradeCost}
        header={title}
        main={
          <div>
            <h6 className={s.description}>{t(description)}</h6>

            <div className={s.countWrapper}>
              <div className={s.iconWrapper}>{mainContent()?.icon}</div>
              <span className={s.userData}>
                {skillValue}
                {mainContent()?.symbol}
              </span>
              <span className={s.profit}>{valueAdd ? `+${valueAdd}${mainContent()?.symbol ?? ''}` : ''}</span>
            </div>
          </div>
        }
        footer={
          <div className={s.cardFooter}>
            <p className={s.level}>
              <span>Lvl</span>
              <span>{skillLevel}</span>
            </p>

            <div className={s.footerTextWrapper}>
              {!valueAdd ? 'Full' : !upgradeCost ? isTimeView() : isPriceView()}
            </div>
          </div>
        }
        marker={isUpdating && <Icon iconName={'updateArrow'} classNames={s.upgradeIcon} />}
      />
    </div>
  );
};
