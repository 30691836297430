import { useTranslation } from 'react-i18next';
import { Button } from 'components';
import { useAppDispatch } from 'hooks';
import { modalIsClose } from 'store/slices/modalSlice';
import s from './styles.module.scss';

export const Notification = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch(modalIsClose());
  };

  return (
    <div className={s.wrapper}>
      <div className={s.textWrapper}>
        <h2>{t('notification.attention')}</h2>
        <h4>{t('notification.canNotStartWork')}</h4>
      </div>

      <Button onClick={handleClose} variant={'Blue'}>
        {t('common.confirm')}
      </Button>
    </div>
  );
};
