import { useTranslation } from 'react-i18next';
import { Friend } from 'shared/types';
import { FriendCardShort } from 'feature';
import { sendManageSlot } from 'store/service/methods';
import { useGetUserData } from 'src/hooks';
import s from './styles.module.scss';

export const AddStaff = ({ data, slot_id }: { data: Friend[]; slot_id: number }) => {
  const { t } = useTranslation();
  const { user_id, slots } = useGetUserData();
  const handleAddStaff = (friend_id: number) => {
    sendManageSlot(slot_id, friend_id);
  };
  const filtered = data?.filter(
    (friend) => friend.owner_id === user_id && !slots.some((slot) => slot.friend_id === friend.id)
  );

  return (
    <div className={s.wrapper}>
      <h4 className={s.modalTitle}>{t('common.addStaff')}</h4>
      {filtered?.length
        ? filtered
            .sort((a, b) => b.power - a.power)
            .map((friend) => (
              <FriendCardShort
                data={friend}
                key={friend.id}
                isDelete={false}
                onClick={() => handleAddStaff(friend.id)}
              />
            ))
        : t('notification.noEmployees')}
    </div>
  );
};
