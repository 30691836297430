import { useCallback } from 'react';
import { RefreshEnergy, Slot } from 'components';
import { ButtonPanel, OfficePlaces } from 'feature';
import { useGetUserData, useWorking } from 'hooks';
import s from './styles.module.scss';

const OfficePage = () => {
  const { isWorking, handleClick } = useWorking();
  const { slots } = useGetUserData();

  const drawTables = useCallback(
    (start, end) => {
      return (
        <>
          {slots
            ?.slice(start, end)
            ?.map((item, index) => (
              <Slot {...item} isWorking={isWorking} key={index} slotIndex={index + start} slot={item} />
            ))}
        </>
      );
    },
    [slots, isWorking]
  );
  return (
    <div className={s.wrapper}>
      <RefreshEnergy />
      <OfficePlaces slotsLength={slots?.length} drawTables={drawTables} />
      <ButtonPanel onTap={handleClick} />
    </div>
  );
};

export default OfficePage;
