import { FC } from 'react';
import cn from 'classnames';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { languages } from 'consts';
import { Icon, TitleWrapper, UserCardsWrapper } from 'components';
import { useAppDispatch, useSettings } from 'hooks';
import { changeLanguage } from 'store/slices/settingsSlice';
import { LanguagesEnum } from 'shared/types';
import s from '../styles.module.scss';

interface ChangeLanguageProps {
  onChange: () => void;
}

export const ChangeLanguage: FC<ChangeLanguageProps> = ({ onChange }) => {
  const { language } = useSettings();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleChangeLanguage = (value: LanguagesEnum) => {
    onChange();
    i18n.changeLanguage(value);
    localStorage.setItem('language', value);
    dispatch(changeLanguage(value));
  };

  return (
    <>
      <TitleWrapper>
        <div className={s.title}>{t('settings.changeLanguage')}</div>
      </TitleWrapper>

      <div className={s.tabsWrapper}>
        {languages?.map(({ label, value }) => (
          <UserCardsWrapper key={value}>
            <div
              className={cn(s.wrapperSettings, {
                [s.changed]: value === language,
              })}
              onClick={() => handleChangeLanguage(value)}
            >
              <h5>{label}</h5>
              <div className={s.tabIcon}>
                <Icon iconName={'settingsArrow'} />
              </div>
            </div>
          </UserCardsWrapper>
        ))}
      </div>
    </>
  );
};
