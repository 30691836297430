import { useTranslation } from 'react-i18next';
import { Avatar, ModalIdType } from 'components';
import { useAppDispatch } from 'hooks';
import { Friend } from 'shared/types';
import { modalIsOpen } from 'store/slices/modalSlice';
import s from './styles.module.scss';

export const FriendInfo = ({ data }: { data: Friend }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleOpenInfo = () => {
    dispatch(modalIsOpen(ModalIdType.HIRE_STAFF));
  };

  return (
    <button className={s.button} onClick={handleOpenInfo}>
      <div className={s.friendInfoWrapper}>
        <Avatar variant={'Employees'} image={data?.photo_url} />

        <div className={s.textInfo}>
          <div>{t('tabMenu.office')}</div>
          <div>
            {data?.first_name} {data?.last_name} {data?.username ? `(${data?.username})` : ''}
          </div>
        </div>
      </div>
    </button>
  );
};
