export enum PATHS {
  BOSS = '/boss',
  OFFICE = '/office',
  FRIENDS = '/friends',
  SHOP = '/shop',
  SETTINGS = '/settings',
  STAFF_OFFICE = '/employeeOffice',
  ERROR = '/error',
  LOCK = '/lock',
  LOGS = '/logs',
}
export enum COMPONENTS {
  BOSS = 'BOSS',
  OFFICE = 'OFFICE',
  FRIENDS = 'FRIENDS',
  SHOP = 'SHOP',
  SETTINGS = 'SETTINGS',
  STAFF_OFFICE = 'STAFF_OFFICE',
  ERROR = 'ERROR',
  LOCK = 'LOCK',
  LOGS = 'LOGS',
}

export const routes = [
  { path: PATHS.BOSS, componentName: COMPONENTS.BOSS },
  { path: PATHS.OFFICE, componentName: COMPONENTS.OFFICE },
  { path: PATHS.FRIENDS, componentName: COMPONENTS.FRIENDS },
  { path: PATHS.SHOP, componentName: COMPONENTS.SHOP },
  { path: PATHS.SETTINGS, componentName: COMPONENTS.SETTINGS },
  { path: PATHS.STAFF_OFFICE, componentName: COMPONENTS.STAFF_OFFICE },
  { path: PATHS.ERROR, componentName: COMPONENTS.ERROR },
  { path: PATHS.LOCK, componentName: COMPONENTS.LOCK },
  { path: PATHS.LOGS, componentName: COMPONENTS.LOGS },
];
