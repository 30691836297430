interface ISuffix {
  value: number;
  symbol: string;
}
export const abbreviateNumber = (number: number): string => {
  const suffixes: ISuffix[] = [
    { value: 1_000_000_000_000, symbol: 'T' },
    { value: 1_000_000_000, symbol: 'B' },
    { value: 1_000_000, symbol: 'M' },
    { value: 1_000, symbol: 'k' },
  ];

  for (let i = 0; i < suffixes.length; i++) {
    if (number >= suffixes[i].value) {
      const abbreviatedNumber = number / suffixes[i].value;
      const formattedNumber = Number.isInteger(abbreviatedNumber)
        ? abbreviatedNumber.toString()
        : abbreviatedNumber.toFixed(1);

      return `${formattedNumber}${suffixes[i].symbol}`;
    }
  }

  return number?.toFixed(0);
};
